import React, { useEffect, useMemo, useState } from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { AnnonceTypeFilters } from "../../../components/annonce-type-filters/annonce-type-filters";
import { PropertyTypeFilters } from "../../../components/property-type-filters/property-type-filters";
import { resources } from "../../../constants/hardcoded-string";
import { AnnoncesReferenceData } from "../../../constants/reference-data";
import { featureSelectCustomStyles } from "../../../constants/select-styles";
import { AnnonceFilterSearchOptionTypes } from "../../../enums/annonce-filter-option-types";
import { AnnonceFilterSetting } from "../../../types/annonce-filter-setting";
import { SelectOption } from "../../../types/select-option";
import { hasOverviewsFeature } from "../../../utils/annonce-util";
import { initializePopover, initializeTooltip } from "../../../utils/plugin-util";
import { FormControlWrapper } from "../../../components/form/form-control-wrapper/form-control-wrapper";

type AnnonceListFiltersContainerProps = AnnoncesReferenceData & {
    showfilter: boolean;
    hideAnnonceFilter: () => void;
    handleFilterAnnonces: (filterSettings: AnnonceFilterSetting, type: AnnonceFilterSearchOptionTypes) => void;
}

export const AnnonceListFiltersContainer = ({ showfilter, hideAnnonceFilter, handleFilterAnnonces, annonceTypes, dynamicProperties, propertyTypes }: AnnonceListFiltersContainerProps) => {
    const [minCost, setMinCost] = useState(25000);
    const [maxCost, setMaxCost] = useState(100000000);
    const [sliderId, setSliderId] = useState("");

    const [filterSettings, setFilterSettings] = useState<AnnonceFilterSetting>({
        annonceType: "all",
        propertyType: "all",
        shouldFilterByNumberOfBedrooms: false,
        shouldFilterByNumberOfBathRooms: false,
        bedroomNumber: "0",
        bathroomNumber: "0",
        features: [],
        range: {
            min: 25000,
            max: 1000000000
        }
    });

    const onUpDate = (value: any) => {
        setMinCost(value[0]);
        setMaxCost(value[1]);

        const newFilterSettings = {
            ...filterSettings,
            range: {
                min: value[0],
                max: value[1]
            }
        }
        setFilterSettings(newFilterSettings);
        handleFilterAnnonces(newFilterSettings, AnnonceFilterSearchOptionTypes.RANGE);
    }

    const handleChangeBedroomNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value === null) {
            value = "1";
        }

        const newFilterSettings = {
            ...filterSettings,
            bedroomNumber: value
        }
        setFilterSettings(newFilterSettings);
        handleFilterAnnonces(newFilterSettings, AnnonceFilterSearchOptionTypes.BEDROOM_NUMBER);

    }

    const handleChangeBathRoomNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value === null) {
            value = "1";
        }


        const newFilterSettings = {
            ...filterSettings,
            bathroomNumber: value
        }
        setFilterSettings(newFilterSettings);
        handleFilterAnnonces(newFilterSettings, AnnonceFilterSearchOptionTypes.BATHROOM_NUMBER);
    }

    const handleFilterAnnonceType = (value: string) => {
        const newFilterSettings = {
            ...filterSettings,
            annonceType: value
        }
        setFilterSettings(newFilterSettings);
        handleFilterAnnonces(newFilterSettings, AnnonceFilterSearchOptionTypes.ANNONCE_TYPE);
    }
    const handleFilterByPropertyType = (value: string) => {
        const newFilterSettings = {
            ...filterSettings,
            propertyType: value
        };
        setFilterSettings(newFilterSettings);
        handleFilterAnnonces(newFilterSettings, AnnonceFilterSearchOptionTypes.PROPERTY_TYPE);
    }

    const handleChangeFeatures = (selectedOptions: Array<SelectOption<string>>) => {
        const [shouldFilterByNumberOfBathRooms, shouldFilterByNumberOfBedrooms] = hasOverviewsFeature(selectedOptions, ['douche', 'chambre']);
        const newFilterSettings = {
            ...filterSettings,
            features: selectedOptions,
            shouldFilterByNumberOfBathRooms,
            shouldFilterByNumberOfBedrooms
        };
        setFilterSettings(newFilterSettings);
        handleFilterAnnonces(newFilterSettings, AnnonceFilterSearchOptionTypes.FEATURES);
    }
    // useEffect(() => {
    //     onUpDate([minCost, maxCost]);
    // }, [minCost, maxCost]);

    useEffect(() => {
        setSliderId(uuidv4())
        const slider = document.getElementById("product-price-range");
        slider?.setAttribute("data-slider-color", "secondary");
    }, []);


    useEffect(() => {
        setTimeout(() => {
            initializePopover(window);
            initializeTooltip(window);
        }, 1000);
    }, []);

    const featureOptions = useMemo(() => {
        return dynamicProperties.map((feature) => {
            return {
                value: feature.value,
                label: feature.label
            }
        });
    }, [dynamicProperties]);


    const annonceFilterResources = resources.pages.annonceList.views.annonceFilter;

    return (
        <React.Fragment>
            <div className="col-lg-4" id="propertyFilters" style={{ display: showfilter ? 'block' : 'none' }}>
                <div className="card border-1 border-radius-1">
                    <div data-simplebar>
                        <div className="card-header d-flex align-items-center">
                            <div className="card-title flex-grow-1 mb-0">{annonceFilterResources.title}</div>
                            <div className="flex-shrink-0">
                                <a className="btn btn-subtle-secondary btn-icon btn-sm myButton" onClick={hideAnnonceFilter}><i className="bi bi-chevron-left"></i></a>
                            </div>
                        </div>
                        <div className="card-body">
                            <FormControlWrapper
                                label={annonceFilterResources.annonceType.title}
                                tooltipText={annonceFilterResources.annonceType.tooltipText}>
                                <div className="d-flex flex-wrap gap-2">
                                    <AnnonceTypeFilters
                                        annonceTypes={annonceTypes}
                                        selectedAnnonceType={filterSettings.annonceType}
                                        handleFilter={handleFilterAnnonceType}
                                    />
                                </div>
                            </FormControlWrapper>

                            <FormControlWrapper
                                label={annonceFilterResources.propertyType.title}
                                tooltipText={annonceFilterResources.propertyType.tooltipText}>
                                <div className="d-flex flex-wrap gap-2">
                                    <PropertyTypeFilters
                                        selectedPropertyType={filterSettings.propertyType}
                                        propertyTypes={propertyTypes}
                                        handleFilterByPropertyType={handleFilterByPropertyType}
                                    />
                                </div>
                            </FormControlWrapper>


                            <FormControlWrapper
                                label={annonceFilterResources.features.title}
                                tooltipText={annonceFilterResources.features.tooltipText}>
                                <div className="row gy-3">
                                    <div className="col-lg-12">
                                        <Select
                                            closeMenuOnSelect={false}
                                            defaultValue={filterSettings.features}
                                            isMulti
                                            options={featureOptions}
                                            styles={featureSelectCustomStyles}
                                            onChange={handleChangeFeatures}
                                        />
                                    </div>
                                </div>

                            </FormControlWrapper>



                            {filterSettings.shouldFilterByNumberOfBedrooms &&
                                <FormControlWrapper
                                    label={annonceFilterResources.bedrooms.title}
                                    tooltipText={annonceFilterResources.bedrooms.tooltipText}>
                                    <div className="col-lg-12">
                                        <div className="input-group mb-3">
                                            <input className="form-control"
                                                type={"number"}
                                                value={filterSettings.bedroomNumber}
                                                onChange={handleChangeBedroomNumber}
                                            />
                                            <span id="basic-addon2" className="input-group-text text-3">{"Chambres"}</span>
                                        </div>
                                    </div>


                                </FormControlWrapper>}

                            {filterSettings.shouldFilterByNumberOfBathRooms &&
                                <FormControlWrapper
                                    label={annonceFilterResources.bathRoom.title}
                                    tooltipText={annonceFilterResources.bathRoom.tooltipText}>
                                    <div className="input-group mb-3">
                                        <input className="form-control"
                                            type={"number"}
                                            value={filterSettings.bathroomNumber}
                                            onChange={handleChangeBathRoomNumber}
                                        />
                                        <span id="basic-addon2" className="input-group-text text-3">{"Douches"}</span>
                                    </div>
                                </FormControlWrapper>
                            }

                            <FormControlWrapper
                                label={annonceFilterResources.priceRange.title}
                                tooltipText={annonceFilterResources.priceRange.tooltipText}>
                                {sliderId &&
                                    <div className="mt-3 mb-3">

                                        <Nouislider
                                            range={{ min: 25000, max: 100000000 }}
                                            start={[minCost, maxCost]}
                                            connect
                                            onSlide={onUpDate}
                                            id={sliderId}
                                        />
                                    </div>
                                }

                                <div className="formCost d-flex gap-2 align-items-center mt-4" >
                                    <div className="input-group mb-3">
                                        <input className="form-control" type="text"
                                            id="minCost" autoComplete="off"
                                            value={`${minCost}`}
                                            onChange={(e: any) => setMinCost(e.target.value)}
                                        />
                                        <span id="basic-addon2" className="input-group-text text-3">{"FCFA"}</span>

                                    </div>

                                    <div className="mb-3"><span className="form-label text-2">A</span></div>
                                    <div className="input-group mb-3">
                                        <input className="form-control"
                                            type="text"
                                            id="maxCost"
                                            autoComplete="off"
                                            value={`${maxCost}`}
                                            onChange={(e: any) => setMaxCost(e.target.value)} />
                                        <span id="basic-addon2" className="input-group-text text-3">{"FCFA"}</span>

                                    </div>
                                </div>


                            </FormControlWrapper>



                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >
    );
}