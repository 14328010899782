import { Annonce } from "../../types/annonce"


export enum AnnonceListActionTypes {
    SET_ANNONCES = "SET_ANNONCES",
    SET_ANNONCES_LOADING = "SET_ANNONCES_LOADING"
}


type SetAnnoncesLoadingAction = {
    type: AnnonceListActionTypes.SET_ANNONCES_LOADING,
    payload: {
        isLoading: boolean
    }
}

export const setAnnoncesLoading = (isLoading: boolean): SetAnnoncesLoadingAction => (
    {
        type: AnnonceListActionTypes.SET_ANNONCES_LOADING,
        payload: {
            isLoading
        }
    }
)


type SetAnnoncesAction = {
    type: AnnonceListActionTypes.SET_ANNONCES,
    payload: {
        annonces: Array<Annonce>
    }
}

export const setAnnonces = (annonces: Array<Annonce>): SetAnnoncesAction => (
    {
        type: AnnonceListActionTypes.SET_ANNONCES,
        payload: {
            annonces
        }
    }
)



export type AnnonceListActions = 
    SetAnnoncesLoadingAction |  SetAnnoncesAction;