export const resources = {
    commonValidationText: {
        required: "Champ requis",
        validationErrorDetected: "Il semble que vous ayez des champs requis non renseignés."
    },
    modals: {
        shareAnnonceModal: {
            title: "Partager l'annonce",
            buttons: {
                copy: {
                    label: "Copier"
                },
                shareOnFacebook: {
                    label: "Partager sur facebook"
                },
                whatsApp: {
                    label: "Partager sur WhatsApp"
                },
                shareByEmail: {
                    label: "Partager par mail"
                }
            }
        }
    },
    common: {
        options: {
            logout: {
                label: "Déconnexion"
            }
        },
        discover: {
            label: 'Découvrir'
        },
        delete: {
            label: "Supprimer"
        },
        retire: {
            label: "Retirer"
        },
        apply: {
            label: "Appliquer les changements"
        }, 
        all: {
            label: "Tous"
        },
        addedDate: {
            label: "Ajoutée "
        },
        updatedDate: {
            label: "Mise à jour "
        }
    },
    pagination: {
        noResultsText: "Pas d'annonce trouvée",
        showingText: "Affiche",
        resultsText: "Resultats",
        buttons: {
            next: {
                label: "Suivant"
            },
            previous: {
                label: "Précedent"
            }
        }
    },
    pages: {
        annonceList: {
            description: {
                title: "Annonces Immobilieres",
                text1: "Retrouvez toutes nos annonces immobilieres ici. Si vous souhaitez acheter un bien immobilier, louer ou réserver un appartement ou une residence, cet espace est fait pour vous." ,
                text2: "Grace a un filtre de recherche avancé vous pourrez retrouver plus facilement votre besoin. Cliquez sur le details d'une annonce, si il vous interesse, remplissez un formulaire de contact pour suciter votre interet et un commercial vous contactera aussitot."
            },
            title: "Annonces",
            loadingMessage: "Chargement des annonces ...",
            noData: "Pas d'annonce",
            buttons: {
                addAnnonce: {
                    label: "Ajouter une annonce"
                },
                filter: {
                    hide: "Cacher",
                    show: "Montrer",
                    label: (showfilter: boolean) => { return `${!showfilter ? 'Montrer' : 'Cacher'} le filtre`; }
                }
            },
            views: {
                annonceFilter: {
                    title: "Filtres de recherche",
                    annonceType: {
                        title: "Type d'annonce",
                        tooltipText: "Vous souhaitez acquerir (Vente) ou louer (Location)"
                    },
                    propertyType: {
                        title: "Type de bien",
                        tooltipText: "Le type de bien que vous recherchez",
                        filterTexts: {
                            all: "Tous"
                        }
                    },
                    bedrooms: {
                        title: "Nombre de Chambres",
                        tooltipText: "Entrez le nombre de chambres"
                    },
                    priceRange: {
                        title: "Intervalle de prix",
                        tooltipText: "Cette option vous permet de definir votre budget minimum et maximum"
                    },
                    bathRoom: {
                        title: "Nombre de Douches",
                        tooltipText: "Entrez le nombre de chambres."
                    },
                    features: {
                        title: "Commodités",
                        tooltipText: "Selectionnez les differentes commodités que vous rechercher"
                    }
                }
            }
        },
        annonceDetails: {
            title: "Détails de l'annonce",
            loadingAnnonce: "Chargement...",
            description: {
                title: "Description"
            },
            features: {
                title: "Comodités"
            },
            overview: {
                title: "Caracteristiques"
            }
        },
      
    }
}