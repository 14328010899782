import { AnnonceDetailsState } from "../../types/state/annonce-details-state"
import { AnnonceDetailsActions, AnnonceDetailsActionTypes } from "../actions/annonce-details-actions"

export const initialComponentDetailsState: AnnonceDetailsState = {
    annonce: null,
    formattedAnnonce: null,
    isLoading: false,
    relatedAnnonces: null
}

export const annonceDetailsReducer = (state: AnnonceDetailsState = initialComponentDetailsState, action: AnnonceDetailsActions): AnnonceDetailsState => {
    switch (action.type) {
        case AnnonceDetailsActionTypes.SET_ANNONCE_LOADING: {
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        }

        case AnnonceDetailsActionTypes.SET_ANNONCE: {
            return {
                ...state,
                isLoading: false,
                annonce: action.payload.annonce
            }
        }

        case AnnonceDetailsActionTypes.SET_FORMATTED_ANNONCE: {
            return {
                ...state,
                formattedAnnonce: action.payload.formattedAnnonce
            }
        }

        case AnnonceDetailsActionTypes.SET_RELATED_ANNONCE: {
            return {
                ...state,
                isLoading: false,
                relatedAnnonces: action.payload.annonces
            }
        }

        default:
            return state;
    }



}