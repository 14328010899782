

import React, { useRef } from "react"
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resources } from "../../../constants/hardcoded-string";
import { ANNONCES_REFERENCE_DATA_TYPES } from "../../../constants/reference-data";
import { CommonReferenceDataPageTypes } from "../../../enums/common-reference-data-page-types";
import { useReferenceDataSelector } from "../../../hooks/use-reference-data-selector";
import { getAnnonceReduxRequest } from "../../../redux-requests/annonces/get-annonce-redux-request";
import { getReferenceDataReduxrequest } from "../../../redux-requests/reference-data/get-reference-data-redux-request";
import { getFormattedAnnonceDetails, getRelatedAnnonces, isLoadingAnnonce } from "../../../redux/selectors/annonce-details-selector";
import { AnnonceDetailsComponent } from "./annonce-details-component/annonce-details-components";

export const AnnonceDetailsContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: annonceId } = useParams<{ id: string }>();

    const formattedAnnonceDetails = useSelector(getFormattedAnnonceDetails);
    const isLoadingAnnonceDetails = useSelector(isLoadingAnnonce);
    const relatedAnnonces = useSelector(getRelatedAnnonces);

    const referenceData = useReferenceDataSelector(ANNONCES_REFERENCE_DATA_TYPES);

    const getData = async () => {
        if (annonceId !== undefined) {
            await getAnnonceReduxRequest(dispatch, navigate)(annonceId);
        }
    }

    useEffect(() => {
        getData();
        getReferenceDataReduxrequest(dispatch)(ANNONCES_REFERENCE_DATA_TYPES, CommonReferenceDataPageTypes.ANNONCE_DETAILS);
    }, []);

    const isLoaded = useMemo(() => {
        return !isLoadingAnnonceDetails && formattedAnnonceDetails !== null && !referenceData.isLoading.annonceDetails && relatedAnnonces !== null;
    }, [isLoadingAnnonceDetails, formattedAnnonceDetails, referenceData.isLoading.annonceDetails, relatedAnnonces]);


    const rootRef = useRef(null);

    useEffect(()=> {
        if(rootRef?.current){
            rootRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return <div ref={rootRef}>
        {!isLoaded && <section className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7">
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-12 align-self-center p-static order-2 text-center">
                        <h1 className="text-9 font-weight-bold">{"Detail de l'annonce"}</h1>
                        <span className="sub-title"></span>
                    </div>
                    <div className="col-md-12 align-self-center order-1">
                        <ul className="breadcrumb breadcrumb-light d-block text-center">
                            <li><a href="/">Accueil</a></li>
                            <li><a href="/annonces">Annonces</a></li>
                            <li className="active">{"Detail de l'annonce"}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>}

        {isLoaded && <section 
        className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7"
         style={{ backgroundImage: `url(${formattedAnnonceDetails?.images[0].path}` }}>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-12 align-self-center p-static order-2 text-center">
                        <h1 className="text-9 font-weight-bold">{formattedAnnonceDetails.title}</h1>
                        <span className="sub-title"></span>
                    </div>
                    <div className="col-md-12 align-self-center order-1">
                        <ul className="breadcrumb breadcrumb-light d-block text-center">
                            <li><a href="/">Accueil</a></li>
                            <li><a href="/annonces">Annonces</a></li>
                            <li className="active">{formattedAnnonceDetails.title}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>}

        {(isLoadingAnnonce && !isLoaded) &&
            <div className="alert alert-info alert-lg alert-dismissible text-center">
                <i className="ri-airplay-line me-3 align-middle fs-lg text-info"></i> {resources.pages.annonceDetails.loadingAnnonce}
            </div>
        }

        {isLoaded && <AnnonceDetailsComponent
            formattedAnnonceDetails={formattedAnnonceDetails}
            relatedAnnonces={relatedAnnonces}
        />}

    </div>
}