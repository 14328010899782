import React from 'react'
import { useMemo } from "react";
import { PropertyTypeFilter } from "./property-type-filter/property-type-filter";
import styles from './property-type-filters.module.scss';
import { getPropertyTypeItems } from "../../utils/annonce-util";

import { resources } from '../../constants/hardcoded-string';
import { PropertyType } from '../../types/property-type';

type PropertyTypeFiltersProps = {
    selectedPropertyType: string
    propertyTypes: Array<PropertyType>;
    handleFilterByPropertyType: (selectedpropertyType: string) => void;
}

export const PropertyTypeFilters = ({ selectedPropertyType, propertyTypes, handleFilterByPropertyType }: PropertyTypeFiltersProps) => {

    const propertyTypeItems = useMemo(() => {
        return getPropertyTypeItems(propertyTypes);
    }, [propertyTypes]);


    return <>
        {
            propertyTypeItems.map((item, key) => {
                return <PropertyTypeFilter
                    key={key}
                    attrName="propertyType"
                    selectedItem={selectedPropertyType}
                    item={item}
                    handleFilter={handleFilterByPropertyType}
                />

            })
        }

       
            <div className={styles.propertyTypeAll}>
                <input type="radio"  className="form-check-input"
                    checked={selectedPropertyType === "all"}
                    name="propertyType"
                    value="all"
                    id="propertyType5"
                    onChange={(e: any) => handleFilterByPropertyType(e.target.value)} />
                <label className="form-check-label" htmlFor="propertyType5">
                   {resources.common.all.label}
                </label>
            </div>
        

    </>
}