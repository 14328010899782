import React from "react";
import { resources } from "../../../constants/hardcoded-string";
import { useCopyToClipboard } from "../../../hooks/use-copy-clipboard";
import styles from './share-annonce-modal.module.scss';
import { initializeTooltip } from "../../../utils/plugin-util";

type ShareAnnonceModalProps = {
    link: string;
}

// const apiWhatsApp = "https://api.whatsapp.com/send/?text=https%3A%2F%2Fyoutu.be%2FFb72odpW7zA%3Fsi%3DsOwlT8yX2dxlxpcq&type=custom_url&app_absent=0";
export const ShareAnnonceModal = ({ link }: ShareAnnonceModalProps) => {
    const [copyToClipboard, copyResult] = useCopyToClipboard();

    const handleClickCopy = () => {
        // Copy the text from the input field into the clipboard
        copyToClipboard(link);
    };

    React.useEffect(() => {
        initializeTooltip(window);
    }, []);

    const modalResources = resources.modals.shareAnnonceModal;

    return <div className={`${styles.shareAnnonceModal} modal fade`}
        id="share-annonce-link-modal"
        tabIndex={-1} role="dialog"
        aria-labelledby="defaultModalLabel"
        aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" id="defaultModalLabel">{resources.modals.shareAnnonceModal.title}</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true">&times;</button>
                </div>
                <div className="modal-body">
                    {/* Vue pour les options de partages Fbk,wathsApp,linkedin, email */}

                    <div className={`row mb-3 mt-3 text-center ${styles.sharingOptionsContainer}`}>
                        <ul className="social-icons social-icons-big mt-2">
                            {/* <!-- Facebook --> */}
                            <li className={styles.fbk}>
                                <a href={`https://facebook.com/share.php?u=${link}`} rel="noreferrer" target="_blank"
                                    data-bs-toggle="tooltip"
                                    data-bs-animation="false"
                                    data-bs-placement="top"

                                    title={modalResources.buttons.shareOnFacebook.label}>
                                    <i className="text-dark fab fa-facebook-f"></i>
                                </a>
                            </li>

                            {/* <!-- WhathsApp --> */}
                            <li className={styles.whatsapp}>
                                <a href={`https://web.whatsapp.com/send?text=${link}`} rel="noreferrer" target="_blank"
                                    data-bs-toggle="tooltip"
                                    data-bs-animation="false"
                                    data-bs-placement="top"

                                    title={modalResources.buttons.whatsApp.label}>
                                    <i className="text-dark fab fa-whatsapp"></i>
                                </a>
                            </li>


                            {/* <!-- Email --> */}
                            <li className={styles.mail}>
                                <a href={`mailto:?subject=GitHub&body=body%20${link}`} data-bs-toggle="tooltip" rel="noreferrer"
                                    data-bs-animation="false"
                                    data-bs-placement="top"
                                    title={modalResources.buttons.shareByEmail.label}>
                                    <i className="text-dark far fa-envelope"></i>
                                </a>
                            </li>
                        </ul>
                    </div>


                    <div className="input-group">
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="" aria-label="" value={link} readOnly />
                            <button className="btn btn-outline-secondary" onClick={handleClickCopy} type="button" id="button-addon2">{resources.modals.shareAnnonceModal.buttons.copy.label}</button>
                        </div>
                    </div>

                    {copyResult?.state === 'success' && (<div className="alert alert-info">{'Copié avec succès'}</div>)}

                    {copyResult?.state === 'error' && `Error: ${copyResult.message}`}
                </div>
                <div className="modal-footer">

                </div>
            </div>
        </div>
    </div>
}