
import { Dispatch } from "@reduxjs/toolkit";
import { setAnnoncesLoading, setAnnonces } from "../../redux/actions/annonce-list-action";
import { getAnnoncesRequest } from "../../requests/annonces/get-annonces-request";

export const getAnnoncesReduxRequest = (dispatch: Dispatch) => async () => {
    dispatch(setAnnoncesLoading(true));
    const { data, error } = await getAnnoncesRequest();

    if (data) {
        dispatch(setAnnonces(data.annonces));

    } else {
        if (error) {
            console.log(error);
        }
        dispatch(setAnnoncesLoading(false));
    }

    return data;
}