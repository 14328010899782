
import { Dispatch } from "@reduxjs/toolkit";
import { setAnnonceLoading, setFormattedAnnonce, setAnnonce, setRelatedAnnonces } from "../../redux/actions/annonce-details-actions";
import { getAnnonceRequest } from "../../requests/annonces/get-annonce-request";
import { getFormattedAnnonce } from "../../utils/annonce-util";
import { getRelatedAnnonceRequest } from "../../requests/annonces/get-related-annonce-request";
import { NavigateFunction } from "react-router-dom";

export const getAnnonceReduxRequest = (dispatch: Dispatch, navigate: NavigateFunction) => async (id: string) => {
    dispatch(setAnnonceLoading(true));
    const { data, error } = await getAnnonceRequest(id);

    if (data) {
        dispatch(setFormattedAnnonce(getFormattedAnnonce(data)));
        dispatch(setAnnonce(data));

        const { data: relatedAnnoncesData, error: relatednnoncesError } = await getRelatedAnnonceRequest(id);

        if (relatedAnnoncesData) {
            dispatch(setRelatedAnnonces(relatedAnnoncesData));

        } else {
            if (error) {
                console.log(relatednnoncesError);
            }
            // dispatch(setAnnonceLoading(false));
        }

    } else {
        if (error) {
            navigate("/not-found")
            console.log(error);
        }
        dispatch(setAnnonceLoading(false));
    }
}