import React from "react";
import { reinitialize } from "../../utils/plugin-util";

export const ServicesContainer = () => {
    document.title = "Services";
    React.useEffect(() => {
        const theme = window["theme"];
        reinitialize(theme, window);
    }, []);

    return <>
    <section className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7" style={{ backgroundImage: "url(/assets/img/page-header-img.png)" }}>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-12 align-self-center p-static order-2 text-center">
                        <h1 className="text-10">Nos <strong>services</strong></h1>
                        <span className="sub-title">{"Nous travaillons à ameliorer vos conditions de vie"}</span>
                    </div>
                    <div className="col-md-12 align-self-center order-1">
                        <ul className="breadcrumb breadcrumb-light d-block text-center">
                            <li><a href="/">Accueil</a></li>
                            <li className="active">Nos services</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
                <div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
                    <div className="feature-box feature-box-style-2">
                        <div className="feature-box-icon">
                            <i className="fa fa-building" aria-hidden="true"></i>
                        </div>
                        <div className="feature-box-info">
                            <h4 className="font-weight-bold mb-2">BTP / Construction</h4>
                            <p>Nous offrons des services de construction de haute qualité, allant de la planification initiale à la livraison finale, avec une attention méticuleuse aux détails et une utilisation de matériaux de première qualité.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 appear-animation" data-appear-animation="fadeInUpShorter">
                    <div className="feature-box feature-box-style-2">
                        <div className="feature-box-icon">
                            <i className="icons icon-layers text-color-primary"></i>
                        </div>
                        <div className="feature-box-info">
                            <h4 className="font-weight-bold mb-2">Rénovation et Réhabilitation</h4>
                            <p>Transformez vos propriétés existantes en espaces modernes et fonctionnels grâce à notre expertise en rénovation et réhabilitation. Nous pouvons donner une nouvelle vie à votre bien immobilier.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
                    <div className="feature-box feature-box-style-2">
                        <div className="feature-box-icon">
                            <i className="icons icon-menu text-color-primary"></i>
                        </div>
                        <div className="feature-box-info">
                            <h4 className="font-weight-bold mb-2">Climatisation</h4>
                            <p>Garantissez le confort de votre domicile ou de votre entreprise grâce à nos services de climatisation professionnels. Nous installons, entretenons et réparons des systèmes de climatisation de pointe.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
                    <div className="feature-box feature-box-style-2">
                        <div className="feature-box-icon">
                            <i className="fa fa-bolt" aria-hidden="true"></i>
                        </div>
                        <div className="feature-box-info">
                            <h4 className="font-weight-bold mb-2">Électricité</h4>
                            <p>{"Nos électriciens qualifiés sont là pour vous aider à résoudre tous vos problèmes électriques. De l'installation de nouveaux circuits à la réparation de pannes, nous avons les compétences nécessaires."}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 appear-animation" data-appear-animation="fadeInUpShorter">
                    <div className="feature-box feature-box-style-2">
                        <div className="feature-box-icon">
                            <i className="icons icon-user text-color-primary"></i>
                        </div>
                        <div className="feature-box-info">
                            <h4 className="font-weight-bold mb-2">Plomberie</h4>
                            <p>{"Les problèmes de plomberie peuvent être stressants, mais nous sommes là pour vous. Nos plombiers experts peuvent résoudre rapidement et efficacement tous les problèmes de plomberie"}.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
                    <div className="feature-box feature-box-style-2">
                        <div className="feature-box-icon">
                            <i className="icons icon-home text-color-primary"></i>
                        </div>
                        <div className="feature-box-info">
                            <h4 className="font-weight-bold mb-2">{"Location d'Appartements / Achat et Vente de Terrains"}</h4>
                            <p>{"Vous êtes à la recherche du lieu idéal pour appeler chez vous ? Notre sélection d'appartements soigneusement choisis vous offre un éventail de choix pour répondre à tous les styles de vie. De studios modernes à des appartements familiaux spacieux."}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div className="container-fluid">
            <div className="row align-items-center bg-color-grey">
                <div className="col-lg-6 p-0">
                    <section className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0" data-plugin-parallax data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}" data-image-src="/assets/img/services/btp-construction.png" style={{ minHeight: "315px" }}>
                    </section>
                </div>
                <div className="col-lg-6 p-0">
                    <section className="section section-no-border h-100 m-0">
                        <div className="row m-0">
                            <div className="col-half-section col-half-section-left">
                                <div className="overflow-hidden">
                                    <h4 className="mb-0 appear-animation" data-appear-animation="maskUp"><a href="#" className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1">BTP / Construction</a></h4>
                                </div>
                                <div className="overflow-hidden mb-2">
                                    <p className="mb-0 lead text-4 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200"></p>
                                </div>
                                <p className="text-2 mb-0 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">Nous offrons des services de construction de haute qualité, allant de la planification initiale à la livraison finale, avec une attention méticuleuse aux détails et une utilisation de matériaux de première qualité.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="row align-items-center bg-color-grey">
                <div className="col-lg-6 order-2 order-lg-1 p-0">
                    <section className="section section-no-border h-100 m-0">
                        <div className="row justify-content-end m-0">
                            <div className="col-half-section col-half-section-right custom-text-align-right">
                                <div className="overflow-hidden">
                                    <h4 className="mb-0 appear-animation" data-appear-animation="maskUp"><a href="#" className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1">Rénovation et Réhabilitation</a></h4>
                                </div>
                                <div className="overflow-hidden mb-2">
                                    <p className="mb-0 lead text-4 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200"></p>
                                </div>
                                <p className="text-2 mb-0 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">Transformez vos propriétés existantes en espaces modernes et fonctionnels grâce à notre expertise en rénovation et réhabilitation. Nous pouvons donner une nouvelle vie à votre bien immobilier.</p>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 p-0">
                    <section className="parallax section section-parallax h-100 m-0" data-plugin-parallax data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}" data-image-src="/assets/img/services/renovation.jpg" style={{ minHeight: "315px" }}>
                    </section>
                </div>
            </div>
            <div className="row align-items-center bg-color-grey">
                <div className="col-lg-6 p-0">
                    <section className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0" data-plugin-parallax data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}" data-image-src="/assets/img/services/clim.jpg" style={{ minHeight: "315px" }}>
                    </section>
                </div>
                <div className="col-lg-6 p-0">
                    <section className="section section-no-border h-100 m-0">
                        <div className="row m-0">
                            <div className="col-half-section col-half-section-left">
                                <div className="overflow-hidden">
                                    <h4 className="mb-0 appear-animation" data-appear-animation="maskUp"><a href="#" className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1">Climatisation</a></h4>
                                </div>
                                <div className="overflow-hidden mb-2">
                                    <p className="mb-0 lead text-4 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200"></p>
                                </div>
                                <p className="text-2 mb-0 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">Garantissez le confort de votre domicile ou de votre entreprise grâce à nos services de climatisation professionnels. Nous installons, entretenons et réparons des systèmes de climatisation de pointe.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>



            <div className="row align-items-center bg-color-grey">
                <div className="col-lg-6 p-0">
                    <section className="section section-no-border h-100 m-0">
                        <div className="row m-0">
                            <div className="col-half-section col-half-section-left">
                                <div className="overflow-hidden">
                                    <h4 className="mb-0 appear-animation" data-appear-animation="maskUp"><a href="#" className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1">{"Location d'Appartements / Achat et Vente de Terrains"}</a></h4>
                                </div>
                                <div className="overflow-hidden mb-2">
                                    <p className="mb-0 lead text-4 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200"></p>
                                </div>
                                <p className="text-2 mb-0 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">{"Vous êtes à la recherche du lieu idéal pour appeler chez vous ? Notre sélection d'appartements soigneusement choisis vous offre un éventail de choix pour répondre à tous les styles de vie. De studios modernes à des appartements familiaux spacieux."}</p>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="col-lg-6 p-0">
                    <section className="parallax section section-parallax h-100 m-0" data-plugin-parallax data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}" data-image-src="/assets/img/services/location-appart.jpg" style={{ minHeight: "315px" }}>
                    </section>
                </div>
            </div>

            <div className="row align-items-center bg-color-grey">
                <div className="col-lg-6 p-0">
                    <section className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0" data-plugin-parallax data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}" data-image-src="/assets/img/services/elec.jpg" style={{ minHeight: "315px" }}>
                    </section>
                </div>
                <div className="col-lg-6 p-0">
                    <section className="section section-no-border h-100 m-0">
                        <div className="row m-0">
                            <div className="col-half-section col-half-section-left">
                                <div className="overflow-hidden">
                                    <h4 className="mb-0 appear-animation" data-appear-animation="maskUp"><a href="#" className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1">{"Électricité"}</a></h4>
                                </div>
                                <div className="overflow-hidden mb-2">
                                    <p className="mb-0 lead text-4 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200"></p>
                                </div>
                                <p className="text-2 mb-0 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">{"Nos électriciens qualifiés sont là pour vous aider à résoudre tous vos problèmes électriques. De l'installation de nouveaux circuits à la réparation de pannes, nous avons les compétences nécessaires."}</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div className="row align-items-center bg-color-grey">

                <div className="col-lg-6 p-0">
                    <section className="section section-no-border h-100 m-0">
                        <div className="row m-0">
                            <div className="col-half-section col-half-section-left">
                                <div className="overflow-hidden">
                                    <h4 className="mb-0 appear-animation" data-appear-animation="maskUp"><a href="#" className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1">Plomberie</a></h4>
                                </div>
                                <div className="overflow-hidden mb-2">
                                    <p className="mb-0 lead text-4 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200"></p>
                                </div>
                                <p className="text-2 mb-0 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">Les problèmes de plomberie peuvent être stressants, mais nous sommes là pour vous. Nos plombiers experts peuvent résoudre rapidement et efficacement tous les problèmes de plomberie.</p>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="col-lg-6 p-0">
                    <section className="parallax section section-parallax h-100 m-0" data-plugin-parallax data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}" data-image-src="/assets/img/services/plomberie.jpg" style={{ minHeight: "315px" }}>
                    </section>
                </div>
            </div>
        </div>

         {/* <div className="container">
            <div className="row py-5 mt-5 mb-4">
                <div className="col-md-6 mb-5 mb-md-0 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="200">
                    <div className="testimonial testimonial-style-2 testimonial-with-quotes testimonial-quotes-dark testimonial-remove-right-quote mb-0">
                        <div className="testimonial-author">
                            <img src="/assets/img/clients/client-1.jpg" className="img-fluid rounded-circle mb-0" alt="" />
                        </div>
                        <blockquote>
                            <p className="text-color-dark text-5 line-height-5 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ornare, risus vel tristique pharetra.</p>
                        </blockquote>
                        <div className="testimonial-author">
                            <p><strong className="font-weight-extra-bold text-3 mb-1">John Doe</strong><span>Okler</span></p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="400">
                    <div className="testimonial testimonial-style-2 testimonial-with-quotes testimonial-quotes-dark testimonial-remove-right-quote mb-0">
                        <div className="testimonial-author">
                            <img src="/assets/img/clients/client-2.jpg" className="img-fluid rounded-circle mb-0" alt="" />
                        </div>
                        <blockquote>
                            <p className="text-color-dark text-5 line-height-5 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum sollicitudin risus, non lacini.</p>
                        </blockquote>
                        <div className="testimonial-author">
                            <p><strong className="font-weight-extra-bold text-3 mb-1">Melinda Doe</strong><span>Okler</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </>

}