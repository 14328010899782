import React from 'react'

import { useEffect } from 'react';
import { reinitialize } from '../../utils/plugin-util';


export default function Carousel() {
    useEffect(() => {
        const theme = window["theme"];
        reinitialize(theme, window);
    }, []);

    return <div className="owl-carousel owl-carousel-light owl-carousel-light-init-fadeIn owl-theme manual dots-inside dots-horizontal-center show-dots-hover nav-style-diamond nav-with-transparency nav-inside nav-inside-plus nav-dark nav-md nav-font-size-md show-nav-hover mb-0" data-plugin-options="{'autoplay': false, 'autoplayTimeout': 7000}" data-dynamic-height="['700px','700px','600px','500px','410px']" style={{ height: "700px" }}>
        <div className="owl-stage-outer">
            <div className="owl-stage">
                {/* <!-- Carousel Slide 2 --> */}
                <div className="owl-item position-relative">
                    <div className="background-image-wrapper position-absolute top-0 left-0 right-0 bottom-0" data-appear-animation="kenBurnsToRight" data-appear-animation-duration="30s" data-plugin-options="{'minWindowWidth': 0}" data-carousel-onchange-show
                     style={{ backgroundImage: "url(/assets/img/slides/slide-img1.png)", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                    <div className="container position-relative z-index-1 h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-lg-9 col-xl-8 position-relative mx-auto mt-5 pt-5">
                                <h3 className="position-relative text-color-light text-5 line-height-5 font-weight-medium px-4 mb-2 appear-animation" data-appear-animation="fadeInDownShorter" data-plugin-options="{'minWindowWidth': 0}">
                                    <span className="position-absolute right-100pct top-50pct transform3dy-n50 opacity-3">
                                        <img src="/assets/img/slides/slide-title-border.png" className="w-auto appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="250" data-plugin-options="{'minWindowWidth': 0}" alt="" />
                                    </span>Bienvenue
                                    <span className="position-absolute left-100pct top-50pct transform3dy-n50 opacity-3">
                                        <img src="/assets/img/slides/slide-title-border.png" className="w-auto appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="250" data-plugin-options="{'minWindowWidth': 0}" alt="" />
                                    </span>
                                </h3>
                                <h2 className="text-color-light font-weight-extra-bold text-12 mb-3 appear-animation" data-appear-animation="blurIn" data-appear-animation-delay="500" data-plugin-options="{'minWindowWidth': 0}">Massita And Co Immobilier</h2>
                                <p className="text-4 text-color-light font-weight-light opacity-7 text-center mb-0" data-plugin-animated-letters data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 30}">{"Votre Partenaire Immobilier en Côte d'Ivoire"}</p>
                                <p className="text-4 text-color-light font-weight-light opacity-7 text-center mb-0" data-plugin-animated-letters data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 30}">{"Nous travaillons à améliorer vos conditions de vie"}</p>



                            </div>
                        </div>
                       
                        {/* <p className="position-absolute bottom-15 right-0 text-color-light font-weight-bold text-5-5 line-height-3 text-end pb-0 pb-lg-5 mb-0 d-none d-sm-block">
                            <span className="d-block position-relative z-index-1 pb-5 ps-lg-3 mb-5-5 appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="1900">#1 Fastest-Growing Company</span>
                            <span className="custom-svg-position-1">
                                <svg className="svg-fill-color-primary mt-1 appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="1200" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 859.45 88.44" xmlSpace="preserve" preserveAspectRatio="none">
                                    <polyline points="7.27,84.78 855.17,84.78 855.17,4.79 84.74,4.79 " />
                                </svg>
                            </span>
                        </p> */}
                    </div>
                </div>

                {/* <!-- Carousel Slide 3 --> */}
                <div className="owl-item position-relative" style={{ backgroundImage: "url(/assets/img/slides/slide-img4-2.png)", backgroundSize: "cover", backgroundPosition: "center" }}>
                    <div className="container position-relative z-index-1 h-100">
                        <div className="row align-items-center h-100 pt-5">
                            <div className="col-xl-8 position-relative ms-auto mt-5 pt-5">
                                <h2 className="position-relative text-color-light font-weight-bold custom-big-text-style-1 custom-big-text-style-1-variation text-center text-lg-end pt-4 mt-5 appear-animation" data-appear-animation="fadeInRightShorterPlus" data-appear-animation-delay="1000">
                                    <span className="d-block position-absolute bottom-100 left-0 transform3dy-p50 w-100 pt-5 ps-5">
                                        <span className="d-inline-flex custom-outline-text-style-1 text-2 text-center appear-animation" data-appear-animation="fadeInRightShortePlus" data-appear-animation-delay="2000">
                                            <span className="d-block pt-5">MODERNITE EXPERIENCE & EXPERTISE</span></span>
                                    </span>
                                    MODERNITE
                                </h2>
                            </div>
                        </div>
                        <p className="position-absolute bottom-15 right-0 text-color-light font-weight-bold text-5-5 line-height-3 text-end pb-0 pb-lg-5 mb-0 d-none d-sm-block">
                            <span className="d-block line-height-1 position-relative z-index-1 pb-5 ps-lg-3 mb-5-5 appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="2200">Notre savoir faire à votre service</span>
                            <span className="custom-svg-position-1">
                                <svg className="svg-fill-color-dark appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="1600" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 859.45 88.44" xmlSpace="preserve" preserveAspectRatio="none">
                                    <polyline points="7.27,84.78 855.17,84.78 855.17,4.79 84.74,4.79 " />
                                </svg>
                            </span>
                        </p>
                    </div>
                </div>





            </div>
        </div>
        <div className="owl-nav mt-5">
            <button type="button" role="presentation" className="owl-prev"></button>
            <button type="button" role="presentation" className="owl-next"></button>
        </div>

    </div>

}