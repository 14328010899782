import { AnnonceListState } from "../../types/state/annonce-list-state";
import { AnnonceListActions, AnnonceListActionTypes } from "../actions/annonce-list-action";

export const initialComponentListState: AnnonceListState = {
    annonces: [],
    isLoading: false
}

export const annonceListReducer = (state: AnnonceListState = initialComponentListState, action: AnnonceListActions): AnnonceListState => {
    switch (action.type) {
        case AnnonceListActionTypes.SET_ANNONCES_LOADING: {
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        }

        case AnnonceListActionTypes.SET_ANNONCES: {
            return {
                ...state,
                isLoading: false,
                annonces: action.payload.annonces
            }
        }

        default:
            return state;
    }



}