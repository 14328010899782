import React, { useRef } from "react";

import { useEffect, useMemo, useState } from "react";

import styles from "./annonces-container.module.scss";
import { useDispatch, useSelector } from "react-redux";

import { AnnonceListFiltersContainer } from "./annonce-list-filters-container/annonce-list-filters-container";
import { AnnonceListViewContainer } from "./annonce-list-view-container/annonce-list-view-container";
import { resources } from "../../constants/hardcoded-string";
import { ANNONCES_REFERENCE_DATA_TYPES } from "../../constants/reference-data";
import { CommonReferenceDataPageTypes } from "../../enums/common-reference-data-page-types";
import { useReferenceDataSelector } from "../../hooks/use-reference-data-selector";
import { getAnnoncesReduxRequest } from "../../redux-requests/annonces/get-annonces-redux-request";
import { getReferenceDataReduxrequest } from "../../redux-requests/reference-data/get-reference-data-redux-request";
import { getIsLoadingAnnonces, getAnnonces } from "../../redux/selectors/annonces-selector";
import { Annonce } from "../../types/annonce";
import { AnnonceFilterSetting } from "../../types/annonce-filter-setting";
import { filterAnnonces } from "../../utils/annonce-util";
import { reinitialize } from "../../utils/plugin-util";

export const AnnoncesContainer = () => {
    const id = "annonce-top";
    const scrolTopId = `#${id}`;
    document.title = "Annonces";
    const annoncesResources = resources.pages.annonceList;
    const annoncesRef = useRef(null);
    const dispatch = useDispatch();
    const [showfilter, setShowfilter] = useState<boolean>(true);
    const isLoadingAnnonces = useSelector(getIsLoadingAnnonces);
    const annonces = useSelector(getAnnonces);

    const referenceData = useReferenceDataSelector(ANNONCES_REFERENCE_DATA_TYPES);
    const [filteredAnnonces, setFilteredAnnonces] = useState<Array<Annonce>>([]);

    useEffect(() => {
        const theme = window["theme"];
        reinitialize(theme, window);
        getAnnoncesReduxRequest(dispatch)();
        getReferenceDataReduxrequest(dispatch)(ANNONCES_REFERENCE_DATA_TYPES, CommonReferenceDataPageTypes.ANNONCES);
    }, []);



    const handleFilterAnnonces = (filterSettings: AnnonceFilterSetting) => {
        let updatedAnnonces: Array<Annonce> = [];

        updatedAnnonces = filterAnnonces(annonces, filterSettings);
        setFilteredAnnonces(updatedAnnonces);
    }

    const toggleFilter = () => {
        setShowfilter(!showfilter);
    }

    const srollToTopPage = () => {
        annoncesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }


    const isLoading = useMemo(() => {
        return isLoadingAnnonces || referenceData.isLoading.annonces;
    }, [isLoadingAnnonces, referenceData.isLoading.annonces]);

    const isLoaded = useMemo(() => {
        return ((!isLoadingAnnonces) && !referenceData.isLoading.annonces)
    }, [isLoadingAnnonces, referenceData.isLoading.annonces]);


    useEffect(() => {
        if (isLoaded) {
            setFilteredAnnonces(annonces);
        }
    }, [isLoaded, annonces]);

    return <div role="main" className="main">

        <section className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7" style={{ backgroundImage: "url(/assets/img/page-header-img.png)" }}>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-12 align-self-center p-static order-2 text-center">
                        <h1 className="text-9 font-weight-bold">Annonces</h1>
                        <span className="sub-title"></span>
                    </div>
                    <div className="col-md-12 align-self-center order-1">
                        <ul className="breadcrumb breadcrumb-light d-block text-center">
                            <li><a href="/">Accueil</a></li>
                            <li className="active">Annonces</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section className="section custom-angled section-angled bg-tertiary border-top-0 pb-0 pb-lg-2 mb-5 mb-lg-0">
            <div className="section-angled-layer-top bg-light"></div>
            <div className="section-angled-layer-bottom bg-light d-none d-lg-block"></div>
            <div className="section-angled-content mb-0 mb-lg-5">
                <div className="container py-5 container-xl-custom custom-container">
                    <div className="row align-items-center justify-content-center pt-5 pb-lg-5">
                        <div className="col-md-8 col-xl-6 mb-md-5 mb-xl-4">
                            <div className="position-relative pb-lg-5 mb-lg-5">
                                <img src="/img/demos/construction-2/about-us/about-1.jpg" className="img-fluid custom-img border-radius-0 max-w-90 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="600ms" alt="" />
                                <img src="/img/demos/construction-2/about-us/about-2.jpg" className="img-fluid custom-img border-radius-0 position-absolute custom-img-about appear-animation" data-appear-animation="expandIn" data-appear-animation-delay="600ms" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-6 mt-lg-5 mt-xl-0 pb-lg-5 mb-lg-5">
                            <div className="ps-md-4 mt-5">
                                <div className="row">
                                    <div className="col">
                                        <p className="mb-1"></p>
                                        <h3 className="text-primary font-weight-bold text-capitalize text-7 mb-2">{annoncesResources.description.title}</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className="text-4 pt-2 mb-4">
                                            {annoncesResources.description.text1}
                                        </p>
                                        <p className="mb-3">
                                            {annoncesResources.description.text2}
                                        </p>
                                        <p className="font-weight-bold mb-3">
                                            <button className="btn btn-primary link-hover-style-1" onClick={srollToTopPage}>Parcourir</button>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div id={id} ref={annoncesRef} className={styles.annonces}>

            <div className="container-fluid pt-3 pb-2 pl-3 pr-3">

                <div className="row">


                    {!isLoading && isLoaded && <AnnonceListFiltersContainer
                        showfilter={showfilter}
                        {...referenceData}
                        hideAnnonceFilter={toggleFilter}
                        handleFilterAnnonces={handleFilterAnnonces}
                    />}
                    <div className="col-lg">
                        <div className="card border-0 border-radius-0 bg-color-grey mb-3">
                            <div className="card-body">
                                <div className=" row align-items-center gy-3">
                                    <div className="col-lg-3">
                                        <h6 className={`card-title mb-1 ${styles.cardTitle}`}>
                                            {resources.pages.annonceList.title}
                                            <span className="badge badge-light bg-color-light-scale-2 badge-md text-color-dark pl-2">{annonces.length}</span>
                                        </h6>
                                    </div>
                                    <div className="col-sm-auto ms-auto d-flex gap-1">
                                        <a className="btn btn-primary mb-2" onClick={toggleFilter}><i className="bi bi-house align-baseline me-1"></i> {resources.pages.annonceList.buttons.filter.label(showfilter)}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(isLoading && !isLoaded) &&
                            <div className="alert alert-info alert-lg alert-dismissible text-center">
                                <i className="ri-airplay-line me-3 align-middle fs-lg text-info"></i> {resources.pages.annonceList.loadingMessage}
                            </div>
                        }
                        {(!isLoading && isLoaded) && <AnnonceListViewContainer pageRef={annoncesRef} annonces={filteredAnnonces} scrolTopId={scrolTopId} />}
                    </div>
                </div>
            </div>
        </div>
    </div>
}
