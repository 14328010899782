import React from "react";
import { initMap, reinitialize } from "../../utils/plugin-util";
import ClassicForm from "../../components/forms/classic-form";

export const ContactsContainer = () => {
    document.title = "Contacts";
    React.useEffect(() => {
        const theme = window["theme"];
        reinitialize(theme, window);
        initMap(theme);
    }, []);

    return <>
        <section className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7" style={{ backgroundImage: "url(/assets/img/page-header-img.png)"}}>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-12 align-self-center p-static order-2 text-center" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="200">
                        <h1 className="text-9 font-weight-bold">Contacts</h1>
                        <span className="sub-title"></span>
                    </div>
                    <div className="col-md-12 align-self-center order-1">
                        <ul className="breadcrumb breadcrumb-light d-block text-center" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="800">
                            <li><a href="/">Accueil</a></li>
                            <li className="active">Contacts</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


        <div className="container pt-4 pb-5 pb-sm-0 my-5">
            <div className="row pb-2 mb-5">
                <div className="col-lg-6 mb-4 mb-lg-0 appear-animation" data-appear-animation="fadeInUpShorterPlus" data-appear-animation-delay="1200">
                    <div className="row">
                        <div className="col-auto">
                            <h3 className="d-block text-color-grey positive-ls-3 font-weight-medium text-2 mb-1">Appelez nous</h3>
                            <a href="tel:002250160271868" className="d-flex align-items-center text-decoration-none text-color-dark text-color-hover-primary font-weight-bold text-3-5">
                                <img width={23} height={23} src="/img/demos/construction/icons/phone.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-dark me-2'}" />
                                (+225) 01 60 27 18 68
                            </a>

                            <a href="tel:002250556667567" className="d-flex align-items-center text-decoration-none text-color-dark text-color-hover-primary font-weight-bold text-3-5">
                                <img width={23} height={23} src="/img/demos/construction/icons/phone.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-dark me-2'}" />
                                (+225) 05 56 66 75 67
                            </a>

                            <a href="tel:002250759620506" className="d-flex align-items-center text-decoration-none text-color-dark text-color-hover-primary font-weight-bold text-3-5">
                                <img width={23} height={23} src="/img/demos/construction/icons/phone.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-dark me-2'}" />
                                (+225) 07 59 62 05 06
                            </a>

                            <a href="tel:0033695445316" className="d-flex align-items-center text-decoration-none text-color-dark text-color-hover-primary font-weight-bold text-3-5">
                                <img width={23} height={23} src="/img/demos/construction/icons/phone.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-dark me-2'}" />
                                WhatsApp (+33) 69 54 45 31 6
                            </a>
                        </div>
                        <div className="col-auto">
                            <h3 className="d-block text-color-grey positive-ls-3 font-weight-medium text-2 mb-1">Mail</h3>
                            <a href="mailto:service-com@maci-immo.com" className="d-flex align-items-center text-decoration-none text-color-dark text-color-hover-primary font-weight-bold text-3-5">
                                <img width={23} height={23} src="/img/demos/construction/icons/email.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-dark me-2'}" />
                                service-com@maci-immo.com
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 appear-animation" data-appear-animation="fadeInUpShorterPlus" data-appear-animation-delay="1600">
                    <h3 className="d-block text-color-grey positive-ls-3 font-weight-medium text-2 mb-1">Addresse</h3>
                    <span className="d-flex align-items-center text-color-dark font-weight-bold text-3-5">
                        <img width={23} height={23} src="/img/demos/construction/icons/location.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-dark me-2'}" />
                        {" Cocody Palmeraie Ephrata lot N° 212 ilot 22 Abidjan Cote d'Ivoire"}
                    </span>
                </div>
            </div>
            <div className="container pt-4 pb-5 pb-sm-0 my-5">
                <div className="row">
                    <div className="col-lg-6 mb-4 mb-lg-0 appear-animation" data-appear-animation="fadeInUpShorterPlus" data-appear-animation-delay="1400">
                        <h2 className="font-weight-bold text-color-dark text-6 mb-0">Nous envoyer un message</h2>
                        <p className="text-3-5 font-weight-light mb-5-5">Contactez-nous. Nous ferons un plaisir de vous aider.</p>
                        <ClassicForm />
                    </div>

                    <div className="col-lg-6 appear-animation" data-appear-animation="fadeInUpShorterPlus" data-appear-animation-delay="1800">
                            <h2 className="font-weight-bold text-color-dark text-6 mb-0"></h2>
                            <p className="text-3-5 font-weight-light mb-5-5"> </p>
                            <div id="googlemaps" className="google-map m-0" style={{ minHeight: "450px" }}></div>
                        </div>
                </div>
            </div>

        </div>
        <div className="position-relative pb-5 d-none d-xl-block">
            <div className="position-absolute transform3dy-n50 left-0">
                <div className="appear-animation" data-appear-animation="fadeInRightShorterPlus" data-appear-animation-delay="1500" data-appear-animation-duration="1500ms">
                    <div className="custom-square-1 bg-primary mt-0 mb-5"></div>
                </div>
            </div>
        </div>
        </>
}