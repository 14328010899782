import React from "react";
import styles from "./annonce-view-item.module.scss"
import { Annonce } from "../../../../types/annonce";
import { Link } from "react-router-dom";
import { AnnonceOverviewPreview } from "../../../../components/annonce-overview-preview/annonce-overview-preview";
import { AnnonceTypeBadge } from "../../../../components/annonce-type-badge/annonce-type-badge";
import { PropertyTypeBadge } from "../../../../components/property-type-badge/property-type-badge";

type AnnonceViewItemProps = {
    annonce: Annonce;
}

export const AnnonceViewItem = ({ annonce }: AnnonceViewItemProps) => {
    const annonceDetailsUrl = `/annonces/${annonce.id}`;

    return <div className="card real-estate-grid-widgets card-animate">
        <div className="card-body p-2">
            <img
                className={`${styles.annonceImg} rounded w-100`}
                src={annonce.images.length > 0 ? annonce.images[0].path : ''}
                alt="img-01.jpg"
                style={{ height: "220px" }} />
            <PropertyTypeBadge annoncePropertyType={annonce.property_type} />
            <AnnonceTypeBadge annonceType={annonce.annonce_type}/>
          
                <h4 className="fs-lg font-weight-semibold text-4 line-height-6 mb-1">{annonce.title}</h4>
           
            <p className="text-muted">
                <i className="bi bi-geo-alt align-baseline me-1"></i>{annonce.address}
            </p>

            {annonce.annonce_specification !== null && <AnnonceOverviewPreview annonceSpecValue={annonce.annonce_specification.value} />}


            <div className="border-hrefp border-dashed d-flex align-items-center justify-content-between gap-3">
                <h5 className="mb-0">{annonce.price} F CFA</h5>
                <Link to={`${annonceDetailsUrl}?mode=view`} className="link-effect">Details
                    <i className="bi bi-chevron-right align-baseline ms-1"></i>
                </Link>
            </div>
        </div>
    </div>
}