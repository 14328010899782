

import React from "react";
import { resources } from "../constants/hardcoded-string";

type PaginationFileProps = {
    currentpages: any;
    pagination: boolean;
    perPageData: number;
    currentPage: number;
    pageNumbers: Array<number>;
    handlenextPage: () => void;
    handleClick: (e: any) => void;
    handleprevPage: () => void;
    data: any;
}
const PaginationFile = (props: PaginationFileProps) => {
    const { currentpages, pagination, perPageData, currentPage, pageNumbers, handlenextPage, handleClick, handleprevPage, data } = props;

    const paginationResources = resources.pagination;


    return (
        <div className="mt-4 mb-4">
            {!currentpages?.length && <div id="noresult">
                <div className="text-center py-4">
                    <div className="avatar-md mx-auto mb-4">
                        <div className="avatar-title bg-light text-primary rounded-circle fs-4xl">
                            <i className="bi bi-search"></i>
                        </div>
                    </div>
                    <h5 className="mt-2">{paginationResources.noResultsText}</h5>
                </div>
            </div>}
            {pagination && <div className={`row align-items-center justify-content-between text-center text-sm-start`} id="pagination-element" style={{ display: "flex" }}>
                <div className="col-sm">
                    <div className="text-muted">
                        {paginationResources.showingText} <span className="fw-semibold">{perPageData}</span> sur <span className="fw-semibold">{data?.length}</span> {paginationResources.resultsText}
                    </div>
                </div>
                <div className="col-sm-auto  mt-3 mt-sm-0">
                    <div className="pagination-wrap hstack gap-2">
                        <ul className="pagination  float-end mb-0">
                            <li className={"page-item"}>
                                {currentPage <= 1 ? (
                                    <button className="page-link  disabled pagination-prev">
                                        <i className="fas fa-angle-left"></i>{paginationResources.buttons.previous.label}</button>) :
                                    (
                                        <button className={`page-link ${currentPage <= 1 ? " disabled pagination-prev" : "pagination-prev"}`} onClick={() => handleprevPage()}>
                                            <i className="fas fa-angle-left"></i>{paginationResources.buttons.previous.label}
                                        </button>
                                    )}
                            </li>


                            {(pageNumbers || []).map((item: any, key: any) => (
                                <React.Fragment key={key}>
                                    <li className={`page-item ${currentPage === item ? "active " : ""}`}>
                                        <button className="page-link" key={key} id={item} onClick={(e) => handleClick(e)}>
                                            {item}
                                        </button>
                                    </li>
                                </React.Fragment>
                            ))}

                            <li className={`page-item ${currentPage >= pageNumbers.length ? "disabled pagination-next" : " pagination-next"}`} >
                                <button  className={"page-link"} onClick={() => handlenextPage()}>{paginationResources.buttons.next.label}
                                    <i className="fas fa-angle-right"></i>
                                </button>

                            </li>

                        </ul>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default PaginationFile;