import React from "react";
import { resources } from "../../constants/hardcoded-string";
import { fromNow } from "../../utils/date-util";

type AnnonceDateViewProps = {
    created_at: string;
    updated_at: string;
}

export const AnnonceDateView = ({ created_at, updated_at }: AnnonceDateViewProps) => {
    return <p className="text-1 ">
        {`${updated_at === null ?
        resources.common.addedDate.label + " " + fromNow(created_at) : resources.common.updatedDate.label + " " + fromNow(updated_at)
        }`}</p>
}