


import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ReferenceDataTypes } from "../enums/reference-data-types";
import { getCommonReferenceDataState } from "../redux/selectors/common-reference-data-selector";
import { CommonReferenceData } from "../types/state/common-reference-data-state";
import { getReferenceData } from "../utils/reference-data-util";

export const useReferenceDataSelector = <T = CommonReferenceData>(referenceDataTypes: Array<ReferenceDataTypes>) => {
    const commonReferenceData = useSelector(getCommonReferenceDataState);

    return useMemo(() => {
        return getReferenceData<T>(referenceDataTypes, commonReferenceData)
    }, [commonReferenceData, referenceDataTypes]);
};