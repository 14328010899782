import React from 'react'

import { Link, useLocation } from 'react-router-dom';
import { menuItems } from '../../constants/menu-options';
import { sitePreferences } from '../../constants/site-preferences';

export const HeaderContainer = () => {
    const routerLocation = useLocation();
    const pathName = routerLocation.pathname;

    return (
        <header
            id="header"
            className="header-transparent header-semi-transparent header-semi-transparent-light"
            data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyStartAt': 1, 'stickySetTop': '1'}"
        >

            <div className="header-body border-0">
                <div className="header-container container">
                    <div className="header-row">
                        <div className="header-column">
                            <div className="header-row">
                                <div className="header-logo custom-header-logo">


                                    <img className="logo" alt="Logo" width="123" height="123" src="/logo.png" />
                                    <a href="/">
                                        <img className="logo-sticky" alt="Logo" width="123" height="123" src="/logo.png" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="header-column justify-content-end">
                            <div className="header-row">
                                <div className="header-nav header-nav-links order-3 order-lg-1">
                                    <div className="header-nav-main header-nav-main-square header-nav-main-text-capitalize header-nav-main-effect-1 header-nav-main-sub-effect-1">
                                        <nav className="collapse px-3-5">
                                            <ul className="nav nav-pills" id="mainNav">
                                                {
                                                    menuItems.map((menuItem, index) => {
                                                        return (<li key={index}>
                                                            <Link
                                                                className={`nav-link ${pathName === menuItem.href ? "active" : ""
                                                                    }`}
                                                                to={menuItem.href}
                                                            >
                                                                {menuItem.label}
                                                            </Link>
                                                        </li>)
                                                    })
                                                }
                                            </ul>
                                        </nav>
                                    </div>
                                    <button className="btn header-btn-collapse-nav" data-bs-toggle="collapse" data-bs-target=".header-nav-main nav">
                                        <i className="fas fa-bars"></i>
                                    </button>
                                </div>
                                <div className="header-nav-features header-nav-features-no-border header-nav-features-lg-show-border d-none d-sm-flex ms-3 order-1 order-lg-2">
                                    <ul className="header-social-icons social-icons d-none d-sm-block social-icons-clean social-icons-medium ms-0">
                                        <li className="social-icons-facebook"><a href={sitePreferences.socialLink.facebook} rel="noreferrer" target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>

                                    </ul>
                                </div>
                                <div className="header-nav-features header-nav-features-no-border header-nav-features-sm-show-border ms-3 ps-4 order-2 order-lg-3">
                                    <div className="header-nav-feature header-nav-features-search d-inline-flex">
                                        <a href="#" className="header-nav-features-toggle text-decoration-none" data-focus="headerSearch">
                                            <i className="icons icon-magnifier header-nav-top-icon text-3-5 text-color-dark text-color-hover-primary font-weight-semibold top-3"></i>
                                        </a>
                                        <div className="header-nav-features-dropdown header-nav-features-dropdown-mobile-fixed border-radius-0" id="headerSearchDropdown">
                                            <form role="search" action="page-search-results.html" method="get">
                                                <div className="simple-search input-group">
                                                    <input className="form-control text-1" id="headerSearch" name="q" type="search" placeholder="Search..." />
                                                    <button className="btn" type="submit">
                                                        <i className="icons icon-magnifier header-nav-top-icon text-color-dark text-color-hover-primary top-2"></i>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </header>
    )
}