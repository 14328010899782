export const menuItems = [
    {
        label: "Accueil",
        href: "/",
        children: null

    },
    {
        label: "Qui sommes nous",
        href: "/about-us",
        children: null

    },
    {
        label: "Services",
        href: "/services",
        children: null

    },
    {
        label: "Annonces",
        href: "/annonces",
        children: null

    },
    {
        label: "Contacts",
        href: "/contacts",
        children: null

    }
    

]