
import { rootReducer } from "./reducers/root-reducer";
import { initialState } from "./initial-state";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore (
    {
        reducer: rootReducer,
        preloadedState: initialState
    }
);

export { store };