import $ from "jquery";
import { Loader } from "@googlemaps/js-api-loader"

export const initializeCarousel = (theme: any) => {
  theme.fn.intObsInit('[data-plugin-carousel]:not(.manual), .owl-carousel:not(.manual)', 'themePluginCarousel');

}

export const initializePopover = (window: any) => {
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new window.bootstrap.Popover(popoverTriggerEl)
  });
}

export const initializeTooltip = (window: any) => {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new window.bootstrap.Tooltip(tooltipTriggerEl)
  });
}

export const initializeScrollTop = (theme: any) => {
  // Scroll to Top Button.
  if (typeof theme.PluginScrollToTop !== 'undefined') {
    theme.PluginScrollToTop.initialize();
  }
}
export const reinitialize = (theme: any, window: any) => {
  if (theme?.fn) {
    // Validations
    if ($.isFunction($.validator) && typeof theme.PluginValidation !== 'undefined') {
      theme.PluginValidation.initialize();
    }

    // Animate

    theme.fn.dynIntObsInit('[data-appear-animation], [data-appear-animation-svg]', 'themePluginAnimate', theme.PluginAnimate.defaults);


    // Animated Content

    theme.fn.intObsInit('[data-plugin-animated-letters]:not(.manual), .animated-letters', 'themePluginAnimatedContent');
    theme.fn.intObsInit('[data-plugin-animated-words]:not(.manual), .animated-words', 'themePluginAnimatedContent');


    // Before / After

    theme.fn.intObsInit('[data-plugin-before-after]:not(.manual)', 'themePluginBeforeAfter');

    // Carousel Light
    theme.fn.intObsInit('.owl-carousel-light', 'themePluginCarouselLight');

    // Carousel
    theme.fn.intObsInit('[data-plugin-carousel]:not(.manual), .owl-carousel:not(.manual)', 'themePluginCarousel');


    // Cursor Effect
    theme.fn.intObsInit('[data-plugin-cursor-effect]:not(.manual)', 'themePluginCursorEffect');


    theme.fn.intObsInit('[data-plugin-hover-effect]:not(.manual), .hover-effect-3d:not(.manual)', 'themePluginHoverEffect');
    theme.fn.intObsInit('[data-plugin-parallax]:not(.manual)', 'themePluginParallax');

    // Scrollable

    theme.fn.intObsInit('[data-plugin-scrollable]', 'themePluginScrollable');


    theme.StickyHeader.initialize();


    // Nav Menu
    theme.Nav.initialize();

    // Search
    theme.Search.initialize();

    // Account
    theme.Account.initialize();

    // Revolution Slider
    if ($.isFunction($.fn['themePluginRevolutionSlider']) && ($('[data-plugin-revolution-slider]').length || $('.slider-container .slider').length)) {

      $(function () {
        $('[data-plugin-revolution-slider]:not(.manual), .slider-container .slider:not(.manual)').each(function () {
          const $this = $(window);
          let opts;

          const pluginOptions = theme.fn.getOptions($this.data('plugin-options'));
          if (pluginOptions)
            opts = pluginOptions;

          $this.themePluginRevolutionSlider(opts);
        });
      });

    }

    // Scroll Spy
    if ($.isFunction($.fn['themePluginScrollSpy']) && $('[data-plugin-scroll-spy]').length) {

      $(function () {
        $('[data-plugin-scroll-spy]:not(.manual)').each(function () {
          const $this = $(window);
          let opts;

          const pluginOptions = theme.fn.getOptions($this.data('plugin-options'));
          if (pluginOptions)
            opts = pluginOptions;

          $this.themePluginScrollSpy(opts);
        });
      });

    }

    // Scrollable
    if ($.isFunction($.fn['nanoScroller']) && $('[data-plugin-scrollable]').length) {
      theme.fn.intObsInit('[data-plugin-scrollable]', 'themePluginScrollable');
    }

    // Section Scroll
    if ($.isFunction($.fn['themePluginSectionScroll']) && $('[data-plugin-section-scroll]').length) {

      $(function () {
        $('[data-plugin-section-scroll]:not(.manual)').each(function () {
          const $this = $(window);
          let opts;

          const pluginOptions = theme.fn.getOptions($this.data('plugin-options'));
          if (pluginOptions)
            opts = pluginOptions;

          $this.themePluginSectionScroll(opts);
        });
      });

    }

    // Masonry
    if ($.isFunction($.fn['themePluginMasonry']) && $('[data-plugin-masonry]').length) {

      $(function () {
        $('[data-plugin-masonry]:not(.manual)').each(function () {
          const $this = $(window);
          let opts;

          const pluginOptions = theme.fn.getOptions($this.data('plugin-options'));
          if (pluginOptions)
            opts = pluginOptions;

          $this.themePluginMasonry(opts);
        });
      });

    }
    // Match Height
    if ($.isFunction($.fn['themePluginMatchHeight']) && $('[data-plugin-match-height]').length) {

      $(function () {
        $('[data-plugin-match-height]:not(.manual)').each(function () {
          const $this = $(window);
          let opts;

          const pluginOptions = theme.fn.getOptions($this.data('plugin-options'));
          if (pluginOptions)
            opts = pluginOptions;

          $this.themePluginMatchHeight(opts);
        });
      });

    }

  }



};
export let map: google.maps.Map;

const loader = new Loader({
  apiKey: "AIzaSyDmocbgTR_v4HGJB9JsL7ZjWMcJaWJQDzY",
  version: "weekly",

});

// const mapMarkers: Array<google.maps.Marker> = [{
//   address: "Massita And Co Immobilier",
//   html: "<strong>Bureaux</strong><br>Cocody Palmeraie Ephrata lot N° 212 ilot 22",
//   icon: {
//     image: "/img/pin.png",
//     iconsize: [26, 46],
//     iconanchor: [12, 46]
//   },
//   popup: true
// }];


async function initGoogleMap(): Promise<void> {

  loader.load().then(async () => {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    const coord = { lat: 5.3838175, lng: -3.9359825 };
    map = new Map(document.getElementById("googlemaps"), {
      center: coord,
      zoom: 16,
      scrollwheel: false,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      overviewMapControl: true,
      panControl: true,
      zoomControl: true,
      title: "Massita And Co Immobilier"

    } as google.maps.MapOptions);


    const infowindow = new google.maps.InfoWindow({
      content: "<strong>Bureaux</strong><br>Cocody Palmeraie Ephrata lot N° 212 ilot 22",
      ariaLabel: "Massita And Co Immobilier",
    });

    const marker = new google.maps.Marker({
      position: coord,
      map,
      icon: {
        url: "/img/pin.png"
      },
      title: "Massita And Co Immobilier",
    });

    marker.addListener("click", () => {
      infowindow.open({
        anchor: marker,
        map,
      });
    });
  });
}

export const initMap = (theme: any) => {
  initGoogleMap();



  // if (theme?.fn) {
  // theme.fn.intObs( '.google-map', 'initializeGoogleMaps()', {} );
  // }

}
