import { ReferenceDataTypes } from "../enums/reference-data-types";
import { AnnonceType } from "../types/annonce-type";
import { Dynamicproperty } from "../types/dynamic-property";
import { PropertyType } from "../types/property-type";
import { CommonReferenceData } from "../types/state/common-reference-data-state";

export type AnnoncesReferenceData = {
    [ReferenceDataTypes.ANNONCE_TYPES]: Array<AnnonceType>;
    [ReferenceDataTypes.DYNAMIC_PROPERTIES]: Array<Dynamicproperty>;
    [ReferenceDataTypes.PROPERTY_TYPES]: Array<PropertyType>;
};

export const ANNONCES_REFERENCE_DATA_TYPES: Array<ReferenceDataTypes> = [
    ReferenceDataTypes.ANNONCE_TYPES,
    ReferenceDataTypes.DYNAMIC_PROPERTIES,
    ReferenceDataTypes.PROPERTY_TYPES,
];


export const REFERENCE_DATA_PROPERTY_NAMES: { [key in ReferenceDataTypes]: keyof CommonReferenceData } = {
    [ReferenceDataTypes.PROPERTY_TYPES]: ReferenceDataTypes.PROPERTY_TYPES,
    [ReferenceDataTypes.ANNONCE_TYPES]: ReferenceDataTypes.ANNONCE_TYPES,
    [ReferenceDataTypes.DYNAMIC_PROPERTIES]: ReferenceDataTypes.DYNAMIC_PROPERTIES
} as const;
