import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ROUTES } from './resources/routes-constants'
import { HomeContainer } from './pages/home/home-container'
import { AboutUsContainer } from './pages/about-us/about-us-container'
import { AnnoncesContainer } from './pages/annonces/annonces-container'
import { ContactsContainer } from './pages/contacts/contacts-container'
import { ServicesContainer } from './pages/services/services-container'
import { AnnonceDetailsContainer } from './pages/annonces/annonce-details/annonce-details-container'
import { Layout } from './layout'
import { NotFound } from 'components/not-found/not-found'

const RootComponent: React.FC = () => {
    return (
            <Layout>
                <Routes>
                    <Route path="*" element={<HomeContainer />} />
                    <Route path={ROUTES.home} element={<HomeContainer />} />
                    <Route path={ROUTES.aboutUs} element={<AboutUsContainer />} />
                    <Route path={ROUTES.services} element={<ServicesContainer />} />
                    <Route path={ROUTES.annonces} element={<AnnoncesContainer />} />
                    <Route path={`${ROUTES.annonces}/:id`} element={<AnnonceDetailsContainer />} />
                    <Route path={ROUTES.contacts} element={<ContactsContainer />} />
                    <Route path={ROUTES.notFound} element={<NotFound />} />
                  
                </Routes>
            </Layout>
    )
}

export default RootComponent
