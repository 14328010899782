import { APIClient } from "../../helpers/api_helper";
import { Annonce } from "../../types/annonce";
import { GenericNetworkResponse } from "../../types/generic-network-response";

export const getAnnoncesRequest = async (): Promise<GenericNetworkResponse<{ annonces: Array<Annonce> }>> => {
    const url = `${process.env.REACT_APP_API_URL}/annonces`;
    const api = new APIClient();
    const response = await api.get(url, null);

    return { data: response.data, error: null };
}
