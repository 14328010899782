
import React from 'react';
import  "./footer-container.scss";
import logo from "./../../assets/logo.png";
import { sitePreferences } from '../../constants/site-preferences';

export const FooterContainer = () => {
    return (
        <footer id="footer" className={`footer overflow-hidden border-0 m-0`} style={{ backgroundImage: "url(/img/demos/construction/backgrounds/background-2.jpg)", backgroundRepeat: "no-repeat", backgroundPosition: "center bottom" }}>
        <div className="container pt-5">
            <div className="row pt-4 mb-5 gy-4">
                <div className="col-lg-2 align-self-center">
                    <a href="/"><img src={logo} alt="Logo" className="img-fluid" style={{ maxWidth: '100px' }} /></a>
                </div>

                <div className="col-lg-4 offset-lg-1">
                    <h4 className="text-color-dark font-weight-bold mb-4-5">Navigation</h4>
                    <ul className="list list-unstyled columns-lg-2">
                        <li>
                            <a href="/about-us" className="text-color-hover-primary"> A propos de Nous</a>
                        </li>
                        <li>
                            <a href="/services" className="text-color-hover-primary"> Nos Services</a>
                        </li>

                        <li>
                            <a href="/annonces" className="text-color-hover-primary"> Annonces</a>
                        </li>
                        <li>
                            <a href="/contacts" className="text-color-hover-primary"> Contacts</a>
                        </li>

                    </ul>
                </div>
                <div className="col-lg-5">
                    <ul className="list list-unstyled list-inline">
                        <li className="list-inline-item d-inline-flex align-items-center">
                            <img width="23" height="23" src="/img/demos/construction/icons/phone.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-dark'}" />
                            <a href="tel:+2250160271868" className="text-decoration-none text-color-dark text-color-hover-primary font-weight-bold text-3-5 ms-2">(+225) 01 60 27 18 68</a>
                        </li>
                        <li className="list-inline-item d-inline-flex align-items-center">
                            <img width="23" height="23" src="/img/demos/construction/icons/phone.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-dark'}" />
                            <a href="tel:+2250556667567" className="text-decoration-none text-color-dark text-color-hover-primary font-weight-bold text-3-5 ms-2">(+225) 05 56 66 75 67</a>
                        </li>
                        <li className="list-inline-item d-inline-flex align-items-center">
                            <img width="23" height="23" src="/img/demos/construction/icons/phone.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-dark'}" />
                            <a href="tel:+2250759620506" className="text-decoration-none text-color-dark text-color-hover-primary font-weight-bold text-3-5 ms-2">(+225) 07 59 62 05 06</a>
                        </li>
                        <li className="list-inline-item d-inline-flex align-items-center">
                            <img width="23" height="23" src="/img/demos/construction/icons/phone.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-dark'}" />
                            <a href="tel:0033695445316" className="text-decoration-none text-color-dark text-color-hover-primary font-weight-bold text-3-5 ms-2">WhatsApp (+33) 69 54 45 31 6</a>
                        </li>
                        <li className="list-inline-item d-inline-flex align-items-center">
                            <img width="23" height="23" src="/img/demos/construction/icons/email.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-dark'}" />
                            <a href="mailto:service-com@maci-immo.com" className="text-decoration-none text-color-dark text-color-hover-primary font-weight-bold text-3-5 ms-2">service-com@maci-immo.com</a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
        <hr />
        <div className="footer-copyright bg-transparent pb-5 mt-5-5">
            <div className="row pb-5">
                <div className="col text-center mb-5">
                    <p className="text-color-grey text-3 mb-3">Massita And Co Immobilier © Copyright 2023. Tous droits réservés. </p>
                    <ul className="footer-social-icons social-icons social-icons-clean social-icons-medium mb-5">

                        <li className="social-icons-facebook">
                            <a href={sitePreferences.socialLink.facebook} target="_blank" rel="noreferrer" title="Facebook"><i className="fab fa-facebook-f text-4"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </footer>
    )
}