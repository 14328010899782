import React from 'react'

import styles from "./property-filter.module.scss";
import { PropertyType } from '../../../types/property-type';

type PropertyTypeFilterProps = {
    attrName: string;
    selectedItem: string;
    item: PropertyType & { icon: string; }
    handleFilter: (selectedItem: string) => void;
}

export const PropertyTypeFilter = ({ attrName, selectedItem, item, handleFilter }: PropertyTypeFilterProps) => {
    const setItem = () => {
        handleFilter(item.value);
    }

    return <div
        className={`${styles.item}`}>
        <input type="radio"
            className="form-check-input"
            name={attrName}
            value={item.value}
            id={item.value}
            checked={item.value === selectedItem}
            onChange={setItem}
        />
        <label
            className={`form-check-label`}
            htmlFor={item.value}>
            {item.label}
        </label>
    </div>
}