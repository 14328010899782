

import { v4 as uuidv4 } from "uuid";
import { Annonce, SimpleAnnonce } from "../types/annonce";
import { AnnonceFilterSetting } from "../types/annonce-filter-setting";
import { AdvancedAnnonceSpecificationValue } from "../types/annonce-specification";
import { AnnonceSpecificationValue } from "../types/annonce-specification-value";
import { AnnonceType } from "../types/annonce-type";
import { FormattedAnnonce } from "../types/formatted-annonce";
import { ImageFile } from "../types/image-file";
import { PropertyType } from "../types/property-type";
import { SelectOption } from "../types/select-option";


const annonceTypesIcons: { [key: string]: string } = {
    "location": "bi-buildings",
    "vente": "bi-building",

}

const propertyTypesIcons: { [key: string]: string } = {
    "residence": "bi-buildings",
    "appartement": "bi-building",
    "villa": " bi-house",
    "immeuble": "bi-building",
}

export const propertyTypesPreviewIcons: { [key: string]: string } = {
    "residence": "bi-buildings",
    "appartement": "bi-building",
    "villa": " bi-house-door",
    "immeuble": "bi-house-door",
}

export const annonceTypesPreviewIcons: { [key: string]: string } = {
    "location": "bi-buildings",
    "vente": "bi-building",

}

export const getPropertyTypeItems = (propertyTypes: Array<PropertyType>): Array<PropertyType & { icon: string; }> => {
    return propertyTypes.map(propertyType => {
        return {
            ...propertyType,
            icon: propertyTypesIcons[propertyType.value]

        }
    });
}


export const getannonceTypeItems = (annonceTypes: Array<AnnonceType>): Array<AnnonceType & { icon: string; }> => {
    return annonceTypes.map(propertyType => {
        return {
            ...propertyType,
            icon: annonceTypesIcons[propertyType.value]

        }
    });
}

const isInPriceRange = (annonce: Annonce, range: { min: number; max: number; }) => {
    return +annonce.price >= range.min && +annonce.price <= range.max
}

export const hasFeatures = (features: Array<SelectOption>, annonce: Annonce) => {
    const { annonce_specification } = annonce;
    if (features.length === 0) {
        return true;
    }

    if (annonce_specification !== null) {
        const value = JSON.parse(annonce_specification.value) as AnnonceSpecificationValue;
        const annoncesFeatureValues = value.features;

        const selectedFeatures = Object.values(annoncesFeatureValues);
        return features.every(feature => selectedFeatures.includes(feature.value));
    }

    return false;
}

export const hasPropertyType = (annonce: Annonce, propertyType: string) => {
    if (propertyType === 'all') {
        return true;
    }
    return annonce.property_type.value === propertyType;
}

export const hasAnnonceType = (annonce: Annonce, annonceyType: string) => {
    if (annonceyType === 'all') {
        return true;
    }
    return annonce.annonce_type.value === annonceyType;
}

export const hasOverview = (overviewValue: string, annonce: Annonce, number: string) => {
    const { annonce_specification } = annonce;

    if (annonce_specification !== null) {
        const value = JSON.parse(annonce_specification.value) as AnnonceSpecificationValue;

        const annoncesOverviewValues = value.overview;

        if (Object.keys(annoncesOverviewValues).includes(overviewValue)) {
            const val = +annoncesOverviewValues[overviewValue];
            return val >= +number;
        }
    }

    return false;

}

export const filterAnnonces = (annonces: Array<Annonce>, filterSettings: AnnonceFilterSetting) => {
    return annonces.reduce((acc, annonce) => {
        let hasExactNumberOfBathRoom = true, hasExactNumberOfBedRoom = true;

        const hasAnnoncePropertyType = hasPropertyType(annonce, filterSettings.propertyType);
        const _hasAnnonceType = hasAnnonceType(annonce, filterSettings.annonceType);

        if (filterSettings.shouldFilterByNumberOfBedrooms) {
            hasExactNumberOfBedRoom = hasOverview('chambre', annonce, filterSettings.bedroomNumber);
        }
        if (filterSettings.shouldFilterByNumberOfBathRooms) {
            hasExactNumberOfBathRoom = hasOverview('douche', annonce, filterSettings.bathroomNumber);
        }

        const hasExactFeatures = hasFeatures(filterSettings.features, annonce);
        const isInRange = isInPriceRange(annonce, filterSettings.range);

        if (hasAnnoncePropertyType && hasExactNumberOfBathRoom
            && hasExactNumberOfBedRoom && hasExactFeatures && isInRange && _hasAnnonceType) {
            acc.push(annonce);
        }
        return acc;

    }, [] as Array<Annonce>);
}



export const filterAnnoncesByOverview = (overviewValue: string, annonces: Array<Annonce>, bathroomNumber: string) => {
    return annonces.filter(annonce => {
        const { annonce_specification } = annonce;

        if (annonce_specification !== null) {
            const value = JSON.parse(annonce_specification.value) as AnnonceSpecificationValue;

            const annoncesOverviewValues = value.overview;

            if (Object.keys(annoncesOverviewValues).includes(overviewValue)) {
                return annoncesOverviewValues[overviewValue] === bathroomNumber;
            }
        }

        return false;
    });
}

export const filterAnnoncesByFeatures = (features: Array<SelectOption>, annonces: Array<Annonce>) => {
    return annonces.filter(annonce => {
        const { annonce_specification } = annonce;
        if (annonce_specification !== null) {
            const value = JSON.parse(annonce_specification.value) as AnnonceSpecificationValue;
            const annoncesFeatureValues = value.features;

            const selectedFeatures = Object.values(annoncesFeatureValues);
            return features.every(feature => selectedFeatures.includes(feature.value));
        }

        return false;
    });
}

export const hasOverviewFeature = (features: Array<SelectOption<string>>, featureKey: string) => {
    return features.some(feature => feature.value === featureKey);
}

export const hasOverviewsFeature = (features: Array<SelectOption<string>>, overviews: Array<string>) => {
    const featuresValues = features.map(feature => feature.value);

    return overviews.reduce((acc, overview) => {
        acc.push(featuresValues.includes(overview));
        return acc;
    }, [] as Array<boolean>);
}

export const getAdvancedSpecificationValues = (value: string): AdvancedAnnonceSpecificationValue => {
    if (value === null) {
        return {
            overview: [],
            features: []
        }
    } else {
        const valueJson = JSON.parse(value) as AnnonceSpecificationValue;

        const { overview, features } = valueJson;

        const updatedOverviews = Object.keys(overview).map(key => {
            return {
                key: key,
                value: overview[key],
                uuid: uuidv4()
            }
        });

        const updatedFeatures = features.map(feature => {
            return {
                value: feature
            }
        });


        return {
            overview: updatedOverviews,
            features: updatedFeatures
        }
    }
}

export const getSimpleAnnonce = (formattedAnnonce: FormattedAnnonce) => {
    const { annonce_specification } = formattedAnnonce;
    const { overview, features } = annonce_specification.value;
    const updatedOverview = overview.reduce((acc, overview) => {
        acc[overview.key] = overview.value;
        return acc;
    }, {});

    const updatedFeatures = features.reduce((acc, feature) => {
        acc.push(feature.value);

        return acc;
    }, []);




    return {
        id: formattedAnnonce.id,
        title: formattedAnnonce.title,
        created_at: formattedAnnonce.created_at,
        updated_at: formattedAnnonce.updated_at,
        price: formattedAnnonce.price,
        description: formattedAnnonce.description,
        images: formattedAnnonce.images,
        status: formattedAnnonce.status,
        address: formattedAnnonce.address,
        property_type_id: formattedAnnonce.property_type_id,
        annonce_type_id: formattedAnnonce.annonce_type_id,
        annonce_specification: {
            ...annonce_specification,
            value: JSON.stringify(
                {
                    overview: updatedOverview,
                    features: updatedFeatures
                }
            )
        }
    }
}

export const getFormattedAnnonce = (annonce: SimpleAnnonce) => {
    const { annonce_specification } = annonce;
    let updatedAnnonceSpecificationValue = {
        overview: [],
        features: []
    };

    if (annonce_specification !== null) {
        updatedAnnonceSpecificationValue = getAdvancedSpecificationValues(annonce_specification.value);
    }

    return {
        id: annonce.id,
        title: annonce.title,
        created_at: annonce.created_at,
        updated_at: annonce.updated_at,
        price: annonce.price,
        description: annonce.description,
        images: annonce.images,
        status: annonce.status,
        address: annonce.address,
        property_type_id: annonce.property_type_id,
        annonce_type_id: annonce.annonce_type_id,
        property_type: annonce.property_type,
        annonce_type: annonce.annonce_type,
        annonce_specification: {
            ...annonce_specification,
            value: updatedAnnonceSpecificationValue
        }
    }
}

export const imageFileToFormData = (imageFiles: Array<ImageFile>): FormData => {
    const formData = new FormData();
    // const images: Array<File> = [];
    imageFiles.forEach((imageFile) => {
        formData.append('images[]', imageFile.file);
    });

    formData.append('category', 'annonce');


    return formData;
}
