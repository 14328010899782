import React from "react";


type AnnonceOverviewPreviewProps = {
    annonceSpecValue: string;
}

export const AnnonceOverviewPreview = ({ annonceSpecValue }: AnnonceOverviewPreviewProps) => {

    const annonceSpecOverviewValues = React.useMemo((): Array<string> => {
        try {
            const overview = JSON.parse(annonceSpecValue)['overview'];
            const overviewKeys = Object.keys(overview);

            return overviewKeys.slice(0, 3).map(key => {
                return `${key}: ${overview[key]}`;
            });
        } catch (e) {
            console.error(e);
        }

        return [];

    }, [annonceSpecValue]);

    return <>
        {
            annonceSpecOverviewValues.length > 0 &&
            <ul className="d-flex align-items-center gap-2 flex-wrap list-unstyled">
                {annonceSpecOverviewValues.map((value, index) => {
                    return <li key={index}>
                        <p className="text-muted mb-0">
                            <i className="bi bi-check-square align-baseline text-primary me-1"></i>{value}
                        </p>
                    </li>;
                })}

            </ul>
        }</>
}