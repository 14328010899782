
import { Dispatch } from "@reduxjs/toolkit";
import { CommonReferenceDataPageTypes } from "../../enums/common-reference-data-page-types";
import { ReferenceDataTypes } from "../../enums/reference-data-types";
import { setPartialCommonReferenceDataLoading, setPartialCommonReferenceData } from "../../redux/actions/common-reference-data-actions";
import { getreferenceDataRequest } from "../../requests/reference-data/get-reference-data-request";

export const getReferenceDataReduxrequest = (dispatch: Dispatch) => async (dataTypes: Array<ReferenceDataTypes>, pageType: CommonReferenceDataPageTypes) => {

    dispatch(setPartialCommonReferenceDataLoading(true, pageType));
    const { data, error } = await getreferenceDataRequest(dataTypes);

    if (data) {
        dispatch(setPartialCommonReferenceData(data, pageType))
    } else {
        if (error) {
            console.log('erreur detectee', error);
        }
        dispatch(setPartialCommonReferenceDataLoading(false, CommonReferenceDataPageTypes.ANNONCES));

    }

    return null;
}