import React from 'react'
import { Provider } from 'react-redux'
import RootComponent from './RootComponent'
import { store } from './redux/store'



// Css

import "./vendor/bootstrap/css/bootstrap.min.css";
import "./vendor/fontawesome-free/css/all.min.css";
import "./vendor/animate/animate.compat.css";
import "./vendor/simple-line-icons/css/simple-line-icons.min.css";
import "./vendor/owl.carousel/assets/owl.carousel.min.css";
import "./vendor/owl.carousel/assets/owl.theme.default.min.css";
import "./vendor/magnific-popup/magnific-popup.min.css";
import "./vendor/bootstrap-icons/bootstrap-icons.css";

import "./css/theme.css";
import "./css/theme-blog.css";
import "./css/theme-elements.css";
import "./css/theme-shop.css";
import "./css/custom.css";
import "./css/demos/demo-construction.css";
import "./css/skins/skin-construction.css";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { BrowserRouter } from 'react-router-dom';

const App: React.FC = () => {

    return (
        <Provider store={store}>
            <>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <RootComponent />

                    {process.env.REACT_APP_USE_CHAT === 'true' && <TawkMessengerReact
                        propertyId="654cae61f2439e1631ed8286"
                        widgetId="1hepoic82" />}

                </BrowserRouter>
            </>
        </Provider>
    )
}

export default App
