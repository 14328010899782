
import { initialCommonReferenceDataState } from "./reducers/common-reference-data-reducer";
import { initialComponentListState } from "./reducers/annonce-list-reducers";
import { initialComponentDetailsState } from "./reducers/annonce-details-reducers";
import { ApplicationState } from "../types/state/application-state";

export const initialState: ApplicationState = {
    annonceList: initialComponentListState,
    annonceDetails: initialComponentDetailsState,
    commonReferenceData: initialCommonReferenceDataState
};