import { SimpleAnnonce } from "../../types/annonce"
import { FormattedAnnonce } from "../../types/formatted-annonce"

export enum AnnonceDetailsActionTypes {
    SET_ANNONCE = "SET_ANNONCE",
    SET_FORMATTED_ANNONCE = "SET_FORMATTED_ANNONCE",
    SET_ANNONCE_LOADING = "SET_ANNONCE_LOADING",
    SET_RELATED_ANNONCE = "SET_RELATED_ANNONCE"
}


type SetAnnonceLoadingAction = {
    type: AnnonceDetailsActionTypes.SET_ANNONCE_LOADING,
    payload: {
        isLoading: boolean
    }
}

export const setAnnonceLoading = (isLoading: boolean): SetAnnonceLoadingAction => (
    {
        type: AnnonceDetailsActionTypes.SET_ANNONCE_LOADING,
        payload: {
            isLoading
        }
    }
)


type SetAnnonceAction = {
    type: AnnonceDetailsActionTypes.SET_ANNONCE,
    payload: {
        annonce: SimpleAnnonce
    }
}

export const setAnnonce = (annonce: SimpleAnnonce): SetAnnonceAction => (
    {
        type: AnnonceDetailsActionTypes.SET_ANNONCE,
        payload: {
            annonce
        }
    }
);

type SetFormattedAnnonceAction = {
    type: AnnonceDetailsActionTypes.SET_FORMATTED_ANNONCE,
    payload: {
        formattedAnnonce: FormattedAnnonce
    }
}

export const setFormattedAnnonce = (formattedAnnonce: FormattedAnnonce): SetFormattedAnnonceAction => (
    {
        type: AnnonceDetailsActionTypes.SET_FORMATTED_ANNONCE,
        payload: {
             formattedAnnonce
        }
    }
);


type SetRelatedAnnoncesAction = {
    type: AnnonceDetailsActionTypes.SET_RELATED_ANNONCE,
    payload: {
        annonces: Array<SimpleAnnonce>
    }
}

export const setRelatedAnnonces = (annonces: Array<SimpleAnnonce>): SetRelatedAnnoncesAction => (
    {
        type: AnnonceDetailsActionTypes.SET_RELATED_ANNONCE,
        payload: {
            annonces
        }
    }
);

export type AnnonceDetailsActions = 
    SetAnnonceLoadingAction |  
    SetAnnonceAction |
    SetFormattedAnnonceAction |
    SetRelatedAnnoncesAction;