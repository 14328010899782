import React from "react";
import { useMemo } from "react";
import { PropertyTypeFilter } from "../property-type-filters/property-type-filter/property-type-filter";
import styles from "./annonce-type-filters.module.scss";
import { resources } from "../../constants/hardcoded-string";
import { AnnonceType } from "../../types/annonce-type";
import { getannonceTypeItems } from "../../utils/annonce-util";

type AnnonceTypeFiltersProps = {
    annonceTypes: Array<AnnonceType>;
    selectedAnnonceType: string;
    handleFilter: (selectedAnnonceType: string) => void;
}

export const AnnonceTypeFilters = ({ annonceTypes, selectedAnnonceType, handleFilter }: AnnonceTypeFiltersProps) => {

    const annonceTypeItems = useMemo(() => {
        return getannonceTypeItems(annonceTypes);
    }, [annonceTypes]);


    return <>
        {
            annonceTypeItems.map((item, key) => {
                return <PropertyTypeFilter
                    key={key}
                    attrName="annonceType"
                    selectedItem={selectedAnnonceType}
                    item={item}
                    handleFilter={handleFilter}
                />

            })
        }

        <div className={`${styles.annonceTypeAll}`}>
            <input
                type="radio"
                className="form-check-input"
                checked={selectedAnnonceType === "all"}
                name="annonceType"
                value="all"
                id="annonceTypeAll"
                onChange={(e: any) => handleFilter(e.target.value)}
            />
            <label className="form-check-label" htmlFor="annonceTypeAll">
            {resources.common.all.label}
            </label>
        </div>

    </>
}