import { CommonReferenceDataState, CommonReferenceDataTypeNames } from "../../types/state/common-reference-data-state";
import { CommonReferenceDataActions, CommonReferenceDataActionTypes } from "../actions/common-reference-data-actions";

export const initialCommonReferenceDataState: CommonReferenceDataState = {
    isLoading: {
        annonces: false,
        annonceDetails: false
    },
    propertyTypes: [],
    dynamicProperties: [],
    annonceTypes: []
}

export const commonReferenceDataReducer = (state: CommonReferenceDataState = initialCommonReferenceDataState, action: CommonReferenceDataActions): CommonReferenceDataState => {
    switch (action.type) {
        case CommonReferenceDataActionTypes.SET_PARTIAL_COMMON_REFERENCE_DATA: {
            return {
                ...state,
                ...action.payload.partialReferenceData,
                isLoading: action.payload.commonReferenceDataPageType ? {
                    ...state.isLoading,
                    [action.payload.commonReferenceDataPageType]: false
                } : state.isLoading
            };
        }
        case CommonReferenceDataActionTypes.SET_PARTIAL_COMMON_REFERENCE_DATA_LOADING: {
            return action.payload.commonReferenceDataPageType ? {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.payload.commonReferenceDataPageType]: action.payload.isLoading
                }
            } : state;
        }
        case CommonReferenceDataActionTypes.ADD_OBJECTS_TO_ARRAY_IN_COMMON_REFERENCE_DATA: {
            const propertyName = action.payload.referenceDataType as CommonReferenceDataTypeNames;
            if (propertyName !== undefined) {
                return {
                    ...state,
                    [propertyName]: [
                        ...state[propertyName],
                        ...action.payload.objectsToAdd
                    ]
                }
            }
            return state;
        }
        default:
            return state;
    }
};