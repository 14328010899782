
import React, { useState, useMemo, useEffect } from "react";
import PaginationFile from "../../../common/PaginationFile";
import { resources } from "../../../constants/hardcoded-string";
import { Annonce } from "../../../types/annonce";
import { AnnonceViewItem } from "./annonce-view-item/annonce-view-item";


type AnnonceListViewContainerProps = {
    annonces: Array<Annonce>;
    scrolTopId: string;
    pageRef: React.MutableRefObject<any>;
}

export const AnnonceListViewContainer = ({ pageRef, annonces }: AnnonceListViewContainerProps) => {

    // Pagination
    const pagination = true;
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [currentpages, setCurrentpages] = useState<any>([]);
    const perPageData = 10;

    const indexOfLast = currentPage * perPageData;
    const indexOfFirst = indexOfLast - perPageData;
    const currentdata = useMemo(() => annonces.slice(indexOfFirst, indexOfLast), [annonces, indexOfFirst, indexOfLast]);

    useEffect(() => {
        setCurrentpages(currentdata)
    }, [currentPage, annonces, currentdata]);

    const srollToTopPage = () => {
        pageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const handleClick = (e: any) => {
        setCurrentPage(Number(e.target.id));
        srollToTopPage();

    };

    const handleprevPage = () => {
        const prevPage = currentPage - 1;
        setCurrentPage(prevPage);
        srollToTopPage();
    };
    const handlenextPage = () => {
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        srollToTopPage();
    };


    const pageNumbers = useMemo(() => {
        const result = [];
        for (let i = 1; i <= Math.ceil(annonces.length / perPageData); i++) {
            result.push(i);
        }

        return result;
    }, [annonces, perPageData]);

    useEffect(() => {
        if (pageNumbers.length && pageNumbers.length < currentPage) {
            setCurrentPage(pageNumbers.length)
        }
    }, [currentPage, pageNumbers.length]);

    return (
        <React.Fragment>
            <div className="col-lg">
                {
                    annonces.length === 0 ? <div className="alert alert-info mb-3">{resources.pages.annonceList.noData}</div> :
                        <><div className="row" id='property-list'>
                            {
                                (currentdata || [])?.map((item: Annonce) => {
                                    return (
                                        <div className="col-lg-6 col-md-12" key={item.id}>
                                            <AnnonceViewItem annonce={item} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                            <hr />
                            <PaginationFile
                                currentpages={currentpages}
                                pagination={pagination}
                                perPageData={perPageData}
                                currentPage={currentPage}
                                pageNumbers={pageNumbers}
                                handlenextPage={handlenextPage}
                                handleClick={handleClick}
                                handleprevPage={handleprevPage}
                                data={annonces} />
                        </>
                }

            </div>
        </React.Fragment>
    );
}