import React from "react";
import { AnnonceType } from "../../types/annonce-type";
import { annonceTypesPreviewIcons } from "../../utils/annonce-util";


type PropertyTypeBadgeProps = {
    annonceType: AnnonceType;
}

export const AnnonceTypeBadge = ({ annonceType }: PropertyTypeBadgeProps) => {
    return <>
        <span className="fs-xxs mb-3 ml-1 badge badge-warning badge-md">
            <i className={`bi ${annonceTypesPreviewIcons[annonceType.value]} align-baseline me-1`}>
            </i>
            {annonceType.label}
        </span>
    </>
}