import React from 'react'
import { PropertyType } from '../../types/property-type';
import { propertyTypesPreviewIcons } from '../../utils/annonce-util';

type PropertyTypeBadgeProps = {
    annoncePropertyType: PropertyType;
}

export const PropertyTypeBadge = ({ annoncePropertyType }: PropertyTypeBadgeProps) => {
    return <>
          <span className="fs-xxs mb-3 ml-1 badge badge-success badge-md">
            <i className={`bi ${propertyTypesPreviewIcons[annoncePropertyType.value]} align-baseline me-1`}>
            </i>
            {annoncePropertyType.label}
            </span>
    </>
}