

import { REFERENCE_DATA_PROPERTY_NAMES } from "../constants/reference-data";
import { ReferenceDataTypes } from "../enums/reference-data-types";
import { CommonReferenceData, CommonReferenceDataState } from "../types/state/common-reference-data-state";

export const getReferenceDataPropertyName = (referenceDataType: ReferenceDataTypes): keyof CommonReferenceData => {
    return REFERENCE_DATA_PROPERTY_NAMES[referenceDataType];
}

export const getReferenceData = <T>(referenceDataTypes: Array<ReferenceDataTypes>, commonReferenceData: CommonReferenceDataState) => {
    const referenceData = referenceDataTypes.reduce((finalReferenceData, referenceDataType) => {
        const propertyName = getReferenceDataPropertyName(referenceDataType);

        if (propertyName !== null) {
            finalReferenceData = {
                ...finalReferenceData,
                [propertyName]: commonReferenceData[propertyName]
            };
        }

        return finalReferenceData;
    }, {} as Partial<T>);

    return { ...referenceData, isLoading: commonReferenceData.isLoading } as T & {
        isLoading: typeof commonReferenceData.isLoading
    };
}

