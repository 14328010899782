import { ReferenceDataTypes } from "../../enums/reference-data-types";
import { APIClient } from "../../helpers/api_helper";

export const getreferenceDataRequest = async(referenceDataTypes: Array<ReferenceDataTypes>) => {
    const referenceDataTypesParameters = referenceDataTypes.join(",");

    const url = `${process.env.REACT_APP_API_URL}/referencedata?dataTypes=${referenceDataTypesParameters}`;
    const api = new APIClient();
    const response = await api.get(url, null);

    return { data: response.data, error: null };
}