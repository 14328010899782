import { GroupBase, StylesConfig } from "react-select";
import { SelectOption } from "../types/select-option";
import { PRIMARY_COLOR } from "./globals";

export const featureSelectCustomStyles:  StylesConfig<SelectOption, true, GroupBase<SelectOption>> = {
    menu: (styles: any, { data }: any) => {
        return {
            ...styles,
            zIndex: 9999999
        }
    },
    multiValue: (styles: any, { data }: any) => {
        return {
            ...styles,
            backgroundColor: PRIMARY_COLOR,
        };
    },
    multiValueLabel: (styles: any, { data }: any) => ({
        ...styles,
        backgroundColor: PRIMARY_COLOR,
        color: "white",
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
        ...styles,
        color: "white",
        backgroundColor: PRIMARY_COLOR,
        ':hover': {
            backgroundColor: PRIMARY_COLOR,
            color: 'white',
        },
    }),
}