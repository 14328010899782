import React from 'react';
import { SimpleAnnonce } from '../../../../../types/annonce';
import { initializeCarousel } from '../../../../../utils/plugin-util';
import { AnnonceDateView } from '../../../../../common/annonce-date-view/annonce-date-view';

type RelatedAnnoncesComponentProps = {
    relatedAnnonces: Array<SimpleAnnonce>;
}

export const RelatedAnnoncesComponent = ({ relatedAnnonces }: RelatedAnnoncesComponentProps) => {
   
    React.useEffect(() => {
        initializeCarousel(window["theme"])
    }, []);

   return <div className="row">
        <div className="col">
            <h4 className="font-weight-bold mb-3">Vous pourriez aussi aimer</h4>
            <hr className="mt-0" />
            <div className="products row">
                <div className="col">
                    <div className="owl-carousel owl-theme nav-style-1 nav-outside nav-outside nav-dark mb-0" data-plugin-options="{'loop': false, 'autoplay': false, 'items': 4, 'nav': true, 'dots': false, 'margin': 20, 'autoplayHoverPause': true, 'autoHeight': true, 'stagePadding': '75', 'navVerticalOffset': '50px'}">

                        {relatedAnnonces.map((relatedAnnonce, index) => {
                            return <div key={index} className="product mb-0">
                                <div className="product-thumb-info border-0 mb-3">

                                    <a href={`/annonces/${relatedAnnonce.id}/`}>
                                        <div className="product-thumb-info-image">
                                            <img alt="" className="img-fluid" src={relatedAnnonce.images[0].path} />

                                        </div>
                                    </a>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <a href="#" className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1">{relatedAnnonce.annonce_type.label} - {relatedAnnonce.property_type.label}</a>
                                        <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0"><a href={`/annonces/${relatedAnnonce.id}/`} className="text-color-dark text-color-hover-primary">{relatedAnnonce.title}</a></h3>
                                    </div>

                                </div>
                                <AnnonceDateView updated_at={relatedAnnonce.updated_at} created_at={relatedAnnonce.created_at}/>
                                   
                                <p className="price text-5 mb-3">
                                    <span className="sale text-color-dark font-weight-semi-bold">{relatedAnnonce.price} FCFA</span>

                                </p>
                            </div>
                        })}



                    </div>
                </div>
            </div>
        </div>
    </div>
}