import React from 'react'

import { ReactNode } from "react";
import { FooterContainer } from './common/footer/footer-container';
import { HeaderContainer } from './common/header/header-container';
import useScript from './hooks/use-script';
import { initializeScrollTop } from './utils/plugin-util';
type LayoutProps = {
    children: ReactNode;
}
export const Layout = ({ children }: LayoutProps) => {
    // useScript("/vendor/jquery/jquery.min.js");
    // useScript("/vendor/plugins/js/plugins.js");
    // useScript("/vendor/elevatezoom/jquery.elevatezoom.min.js");
    // useScript("/js/theme.js");

    useScript("/js/views/view.shop.js");
    useScript("/vendor/circle-flip-slideshow/js/jquery.flipshow.min.js");
    useScript("/js/views/view.home.js");
    useScript("/js/theme.init.js");

    React.useEffect(() => {
        const theme = window["theme"];
        initializeScrollTop(theme);
    }, []);
    return (
        <>
            <HeaderContainer />
            <div className="main" role="main">
                {children}
            </div>
            <FooterContainer />
        </>)
}