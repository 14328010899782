import React from 'react'

import { reinitialize } from '../../utils/plugin-util';

export const AboutUsContainer = () => {
    document.title = "Qui sommes nous";
    React.useEffect(() => {
        const theme = window["theme"];
        reinitialize(theme, window);
    }, []);

    return <>
        <section className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7" style={{ backgroundImage: "url(/assets/img/page-header-img.png)" }}>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-12 align-self-center p-static order-2 text-center">
                        <h1 className="text-9 font-weight-bold">Qui sommes nous</h1>
                        <span className="sub-title"></span>
                    </div>
                    <div className="col-md-12 align-self-center order-1">
                        <ul className="breadcrumb breadcrumb-light d-block text-center">
                            <li><a href="/">Accueil</a></li>
                            <li className="active">Qui sommes nous</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">


            <div className="row pt-5">
                <div className="col">
                    <div className="row text-center pb-5">
                        <div className="col-md-9 mx-md-auto">
                            <div className="overflow-hidden mb-3">
                                <h1 className="word-rotator slide font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">
                                    <span>{"Nous sommes Massita And Co Immobilier, Nous "}</span>
                                    <span className="word-rotator-words bg-primary">
                                        <b className="is-visible">{"Fournissons des Solutions"}</b>
                                        <b>{"Vous accompagnons dans votre projet immobilier"}</b>

                                    </span>

                                </h1>
                            </div>
                            <div className="overflow-hidden mb-3">
                                <p className="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">
                                    Nous operons dans les domaines suivants
                                </p>
                            </div>

                            <div>
                                <div className="row">
                                    <div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
                                        <div className="feature-box feature-box-style-2">
                                            <div className="feature-box-icon">
                                                <i className="fa fa-building" aria-hidden="true"></i>
                                            </div>
                                            <div className="feature-box-info">
                                                <h4 className="font-weight-bold mb-2">BTP / Construction</h4>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 appear-animation" data-appear-animation="fadeInUpShorter">
                                        <div className="feature-box feature-box-style-2">
                                            <div className="feature-box-icon">
                                                <i className="icons icon-layers text-color-primary"></i>
                                            </div>
                                            <div className="feature-box-info">
                                                <h4 className="font-weight-bold mb-2">Rénovation et Réhabilitation</h4>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
                                        <div className="feature-box feature-box-style-2">
                                            <div className="feature-box-icon">
                                                <i className="icons icon-menu text-color-primary"></i>
                                            </div>
                                            <div className="feature-box-info">
                                                <h4 className="font-weight-bold mb-2">Climatisation</h4>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
                                        <div className="feature-box feature-box-style-2">
                                            <div className="feature-box-icon">
                                                <i className="fa fa-bolt" aria-hidden="true"></i>
                                            </div>
                                            <div className="feature-box-info">
                                                <h4 className="font-weight-bold mb-2">Électricité</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 appear-animation" data-appear-animation="fadeInUpShorter">
                                        <div className="feature-box feature-box-style-2">
                                            <div className="feature-box-icon">
                                                <i className="icons icon-user text-color-primary"></i>
                                            </div>
                                            <div className="feature-box-info">
                                                <h4 className="font-weight-bold mb-2">Plomberie</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
                                        <div className="feature-box feature-box-style-2">
                                            <div className="feature-box-icon">
                                                <i className="icons icon-home text-color-primary"></i>
                                            </div>
                                            <div className="feature-box-info">
                                                <h4 className="font-weight-bold mb-2">{"Location d'Appartements / Achat et Vente de Terrains"}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-5">
                        <div className="col-md-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="800">
                            <h3 className="font-weight-bold text-4 mb-2">Notre Mission</h3>
                            <p>{"Chez Massita And Co Immobilier, notre mission est claire et passionnée : nous sommes déterminés à vous aider à réaliser vos rêves immobiliers. Nous nous engageons à fournir un service exceptionnel à chaque étape de votre parcours immobilier."}</p>
                        </div>
                        <div className="col-md-4 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="600">
                            <h3 className="font-weight-bold text-4 mb-2">Notre Vision</h3>
                            <p>{`Nous aspirons à créer un avenir immobilier exceptionnel pour nos clients, notre communauté et notre équipe. Notre vision est ancrée dans un engagement indéfectible envers l'excellence, l'innovation et la responsabilité sociale.
                            Notre vision s'étend au-delà d'une simple transaction immobilière. Nous cherchons à établir des partenariats à long terme avec nos clients, à les soutenir tout au long de leur parcours immobilier et à être leur conseiller de confiance.`}

                            </p>

                        </div>
                        <div className="col-md-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="800">
                            <h3 className="font-weight-bold text-4 mb-2">Pourquoi nous</h3>
                            <p>{`Lorsqu'il s'agit de prendre des décisions cruciales dans le domaine de l'immobilier, choisir le bon partenaire peut faire toute la différence. Nous nous efforçons d'être bien plus qu'une simple agence immobilière. Voici pourquoi nous sommes votre choix incontestable pour tous vos besoins immobiliers.`}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <section className="section section-height-3 bg-color-grey-scale-1 m-0 border-0">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 pb-sm-4 pb-lg-0 pe-lg-5 mb-sm-5 mb-lg-0">
                        <h2 className="text-color-dark font-weight-normal text-6 mb-2">{"Une Équipe d'Experts à Votre Service"}</h2>
                        <p className="pe-5 me-5">{"Notre équipe est composée d'experts chevronnés dans le domaine immobilier, chacun apportant une expertise unique à la table. Nous comprenons les marchés locaux, nationaux et internationaux, et nous utilisons cette connaissance approfondie pour vous offrir des conseils pertinents et précis. Que vous soyez un acheteur, un vendeur ou un investisseur, nous sommes là pour vous guider à chaque étape du processus."}</p>

                    </div>
                    <div className="col-sm-8 col-md-6 col-lg-4 offset-sm-4 offset-md-4 offset-lg-2 position-relative mt-sm-5" style={{ top: "1.7rem" }}>
                        <img src="/assets/img/generic-corporate-3-1.png" className="img-fluid position-absolute d-none d-sm-block appear-animation" data-appear-animation="expandIn" data-appear-animation-delay="300" style={{ top: "33%", left: "-50%" }} alt="" />
                        <img src="/assets/img/generic-corporate-3-2.png" className="img-fluid position-absolute d-none d-sm-block appear-animation" data-appear-animation="expandIn" style={{ top: "-33%", left: "-55%" }} alt="" />
                        <img src="/assets/img/generic-corporate-3-3.png" className="img-fluid position-relative appear-animation mb-2" data-appear-animation="expandIn" data-appear-animation-delay="600" alt="" />
                    </div>
                </div>
            </div>
        </section>

        <div className="container appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300">
            <div className="row pt-5 pb-4 my-5">
                <div className="col-md-6 order-2 order-md-1 text-center text-md-start">
                    <div className="owl-carousel owl-theme nav-style-1 nav-center-images-only stage-margin mb-0" data-plugin-options="{'responsive': {'576': {'items': 1}, '768': {'items': 1}, '992': {'items': 2}, '1200': {'items': 2}}, 'margin': 25, 'loop': false, 'nav': true, 'dots': false, 'stagePadding': 40}">
                        <div>
                            <img className="img-fluid rounded-0 mb-4" src="/assets/img/partenaires/bamba-corporation.png" alt="" />
                            <h3 className="font-weight-bold text-color-dark text-4 mb-0">Bamba Corporation</h3>
                            <p className="text-2 mb-0"></p>
                        </div>
                        <div>
                            <img className="img-fluid rounded-0 mb-4" src="/img/team/team-2.jpg" alt="" />
                            <h3 className="font-weight-bold text-color-dark text-4 mb-0">Fadiga Corporation</h3>
                            <p className="text-2 mb-0"></p>
                        </div>
                        <div>
                            <img className="img-fluid rounded-0 mb-4" src="/assets/img/partenaires/hbs.jpeg" alt="" />
                            <h3 className="font-weight-bold text-color-dark text-4 mb-0">ETS MBS</h3>
                            <p className="text-2 mb-0"></p>
                        </div>


                    </div>
                </div>
                <div className="col-md-6 order-1 order-md-2 text-center text-md-start mb-5 mb-md-0">
                    <h2 className="text-color-dark font-weight-normal text-6 mb-2 pb-1">Nos  <strong className="font-weight-extra-bold">partenaires</strong></h2>
                    <p className="lead">{"Chez Massita And Co , nous croyons fermement en la puissance des partenariats. C'est pourquoi nous sommes fiers de collaborer avec les acteurs les plus dynamiques du domaine de l'immobilier pour créer un avenir immobilier exceptionnel."}

                    </p>
                    <p className="mb-4">{"Au-delà de la simple collaboration, nos partenaires partagent notre vision d'une communauté prospère, où l'immobilier ne se limite pas à des transactions, mais devient le fondement de la vie. "}</p>
                </div>
            </div>
        </div>
    </>
}