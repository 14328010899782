import Carousel from "../../common/carousel/carousel"
import React, { useEffect } from 'react'
import ClassicForm from "../../components/forms/classic-form"
import { reinitialize } from "../../utils/plugin-util"

export const HomeContainer = () => {
    document.title = "Accueil";

    useEffect(() => {
        const theme = window["theme"];
        reinitialize(theme, window);
    }, []);
    
    return (

        // <Layout>
            <>
                <Carousel />
                <>

                    <section className="section bg-color-grey-scale-1 section-height-3 border-0 m-0">

                    </section>

                    <section className="section section-secondary border-0 py-0 m-0 appear-animation" data-appear-animation="fadeIn">
                        <div className="container">
                            <div className="row align-items-center justify-content-center justify-content-lg-between pb-5 pb-lg-0">
                                <div className="col-lg-5 order-2 order-lg-1 pt-4 pt-lg-0 pb-5 pb-lg-0 mt-5 mt-lg-0 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">
                                    <h2 className="font-weight-bold text-color-light text-7 mb-2">Qui sommes nous</h2>
                                    <p className="lead font-weight-light text-color-light text-4">{"MACI est une entreprise immobilière composée de consultants très expérimentés dans le domaine de l'immobilier."}</p>
                                    <p className="font-weight-light text-color-light text-2 mb-4 opacity-7">{"Nous créons des espaces de vie de qualité, en nous engageant dans la construction, la rénovation et la réhabilitation de projets immobiliers exceptionnels. Notre expertise en climatisation, électricité et plomberie garantit que chaque détail est pris en compte pour offrir des environnements confortables et fonctionnels"}</p>
                                    <a href="/about-us" className="btn btn-secondary btn-px-5 btn-py-2 text-2">Voir Plus</a>
                                </div>
                                <div className="col-9 offset-lg-1 col-lg-5 order-1 order-lg-2 scale-2">
                                    <img className="img-fluid box-shadow-3 my-2 border-radius" src="/assets/img/about-us-img.png" alt="" style={{ backgroundSize: "cover", width: "586px", height: "624px" }} />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section bg-color-grey-scale-1 section-height-3 border-0 m-0">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h2 className="font-weight-normal text-center text-6 pb-3">Nos <strong className="font-weight-extra-bold">Services</strong></h2>
                                </div>
                            </div>
                            <div className="row mb-lg-4">
                                <div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
                                    <div className="feature-box feature-box-style-2">
                                        <div className="feature-box-icon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </div>
                                        <div className="feature-box-info">
                                            <h4 className="font-weight-bold mb-2">BTP / Construction</h4>
                                            <p>{"Nous offrons des services de construction de haute qualité, allant de la planification initiale à la livraison finale, avec une attention méticuleuse aux détails et une utilisation de matériaux de première qualité."}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 appear-animation" data-appear-animation="fadeInUpShorter">
                                    <div className="feature-box feature-box-style-2">
                                        <div className="feature-box-icon">
                                            <i className="icons icon-layers text-color-primary"></i>
                                        </div>
                                        <div className="feature-box-info">
                                            <h4 className="font-weight-bold mb-2">Rénovation et Réhabilitation</h4>
                                            <p>{"Transformez vos propriétés existantes en espaces modernes et fonctionnels grâce à notre expertise en rénovation et réhabilitation. Nous pouvons donner une nouvelle vie à votre bien immobilier."}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
                                    <div className="feature-box feature-box-style-2">
                                        <div className="feature-box-icon">
                                            <i className="icons icon-menu text-color-primary"></i>
                                        </div>
                                        <div className="feature-box-info">
                                            <h4 className="font-weight-bold mb-2">Climatisation</h4>
                                            <p>{"Garantissez le confort de votre domicile ou de votre entreprise grâce à nos services de climatisation professionnels. Nous installons, entretenons et réparons des systèmes de climatisation de pointe."}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
                                    <div className="feature-box feature-box-style-2">
                                        <div className="feature-box-icon">
                                            <i className="fa fa-bolt" aria-hidden="true"></i>
                                        </div>
                                        <div className="feature-box-info">
                                            <h4 className="font-weight-bold mb-2">Électricité</h4>
                                            <p>{"Nos électriciens qualifiés sont là pour vous aider à résoudre tous vos problèmes électriques. De l'installation de nouveaux circuits à la réparation de pannes, nous avons les compétences nécessaires."}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 appear-animation" data-appear-animation="fadeInUpShorter">
                                    <div className="feature-box feature-box-style-2">
                                        <div className="feature-box-icon">
                                            <i className="icons icon-user text-color-primary"></i>
                                        </div>
                                        <div className="feature-box-info">
                                            <h4 className="font-weight-bold mb-2">Plomberie</h4>
                                            <p>{"Les problèmes de plomberie peuvent être stressants, mais nous sommes là pour vous. Nos plombiers experts peuvent résoudre rapidement et efficacement tous les problèmes de plomberie."}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
                                    <div className="feature-box feature-box-style-2">
                                        <div className="feature-box-icon">
                                            <i className="icons icon-home text-color-primary"></i>
                                        </div>
                                        <div className="feature-box-info">
                                            <h4 className="font-weight-bold mb-2">{"Location d'Appartements / Achat et Vente de Terrains"}</h4>
                                            <p>{"Vous êtes à la recherche du lieu idéal pour appeler chez vous ? Notre sélection d'appartements soigneusement choisis vous offre un éventail de choix pour répondre à tous les styles de vie. De studios modernes à des appartements familiaux spacieux."}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <a href="/services" className="btn btn-primary btn-px-5 btn-py-2 text-center">Voir Plus</a>
                            </div>
                        </div>
                    </section>


                    <div className="container pt-5 pb-5 mb-5">
                        <div className="row justify-content-center">
                            <h2 className="font-weight-normal text-color-dark text-center text-8 mb-4"><strong className="font-weight-extra-bold">Nous</strong> Contacter</h2>
                            <p className="text-4 opacity-8 text-center mb-4">{"Nous sommes disponibles pour toute préoccupation, information supplémentaire devis et autres. N'hésitez pas à soumettre vos informations dans le formulaire ci-dessous"}</p>

                            <ClassicForm />

                        </div>
                    </div>
                </>
            </>
           
            )
}