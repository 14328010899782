import { CommonReferenceDataPageTypes } from "../../enums/common-reference-data-page-types";
import { CommonReferenceDataState, CommonReferenceDataTypeNames } from "../../types/state/common-reference-data-state";

export enum CommonReferenceDataActionTypes {
    SET_PARTIAL_COMMON_REFERENCE_DATA_LOADING = "SET_PARTIAL_COMMON_REFERENCE_DATA_LOADING",
    SET_PARTIAL_COMMON_REFERENCE_DATA = "SET_PARTIAL_COMMON_REFERENCE_DATA",
    ADD_OBJECTS_TO_ARRAY_IN_COMMON_REFERENCE_DATA = "ADD_OBJECTS_TO_ARRAY_IN_COMMON_REFERENCE_DATA"
}

type SetPartialCommonReferenceDataLoadingAction = {
    type: CommonReferenceDataActionTypes.SET_PARTIAL_COMMON_REFERENCE_DATA_LOADING,
    payload: {
        isLoading: boolean;
        commonReferenceDataPageType: CommonReferenceDataPageTypes;
    }
}

export const setPartialCommonReferenceDataLoading = (
    isLoading: boolean,
    commonReferenceDataPageType: CommonReferenceDataPageTypes
): SetPartialCommonReferenceDataLoadingAction => ({
    type: CommonReferenceDataActionTypes.SET_PARTIAL_COMMON_REFERENCE_DATA_LOADING,
    payload: { isLoading, commonReferenceDataPageType }
});

type SetPartialCommonReferenceDataAction = {
    type: CommonReferenceDataActionTypes.SET_PARTIAL_COMMON_REFERENCE_DATA,
    payload: {
        partialReferenceData: Partial<CommonReferenceDataState>
        commonReferenceDataPageType: CommonReferenceDataPageTypes
    }
}

export const setPartialCommonReferenceData = (
    partialReferenceData: Partial<CommonReferenceDataState>,
    commonReferenceDataPageType: CommonReferenceDataPageTypes
): SetPartialCommonReferenceDataAction => ({
    type: CommonReferenceDataActionTypes.SET_PARTIAL_COMMON_REFERENCE_DATA,
    payload: { partialReferenceData, commonReferenceDataPageType }
});

type AddObjectsToArrayInCommonReferenceDataAction = {
    type: CommonReferenceDataActionTypes.ADD_OBJECTS_TO_ARRAY_IN_COMMON_REFERENCE_DATA,
    payload: {
        objectsToAdd: Array<any>;
        referenceDataType: CommonReferenceDataTypeNames
    }
};

export const addObjectsToArrayInCommonReferenceData = (
    objectsToAdd: Array<any>,
    referenceDataType: CommonReferenceDataTypeNames
): AddObjectsToArrayInCommonReferenceDataAction => ({
    type: CommonReferenceDataActionTypes.ADD_OBJECTS_TO_ARRAY_IN_COMMON_REFERENCE_DATA,
    payload: { objectsToAdd, referenceDataType }
});

export type CommonReferenceDataActions =
    SetPartialCommonReferenceDataLoadingAction |
    SetPartialCommonReferenceDataAction |
    AddObjectsToArrayInCommonReferenceDataAction;