import React from 'react'
import { useState } from "react";

type ContactFormData = {
    name: string;
    mail: string;
    subject: string;
    contact: string;
    message: string;
}

type Response = {
    errorMessage: string;
    response: string;
}

const initialState = {
    name: "",
    contact: "",
    mail: "",
    subject: "",
    message: ""
};
export default function ClassicForm() {

    const [state, setState] = useState<ContactFormData>(initialState);

    // const { pending } = useFormStatus()

    const [requestInProgress, setRequestInProgress] = useState(false);
    const [status, setStatus] = useState<Response>({
        response: null,
        errorMessage: null
    });



    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            name: e.target.value
        })
    }

    const handleContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, maxLength } = e.target;
        const contact = value.slice(0, maxLength);

        setState({
            ...state,
            contact
        })
    }


    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            mail: e.target.value
        })
    }

    const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            subject: e.target.value
        })
    }

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setState({
            ...state,
            message: e.target.value
        })
    }

    const submit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        setRequestInProgress(true);
        const url = `${process.env.REACT_APP_MAIL_BACKEND_HOST}/php/contact-form.php`
        const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Accept": "application/json, text/plain, */*"
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(state)
        });

        if (response) {
            response.json().then((data) => {
                setStatus(data);

                setTimeout(() => {
                    setStatus({
                        errorMessage: null,
                        response: null
                    });

                }, 7000)
            });

            setState(initialState);

        }

        setRequestInProgress(false);


    }



    return <form className="contact-form form-style-3" onSubmit={submit} >
        {status.response === "success" && <div className="contact-form-success alert alert-success mt-4">
            <strong>Success!</strong>{" Votre message a été envoyé avec succès"}
        </div>}

        {status.response === "error" && <div className="contact-form-error alert alert-danger d-none mt-4">
            <strong>Error!</strong>{" Une erreur a été détectée. Veuillez essayer ultérieurement"}
            <span className="mail-error-message text-1 d-block"></span>
        </div>}

        <div className="row">
            <div className="form-group col mb-3-5">
                <input type="text"
                    onChange={handleNameChange}
                    value={state.name}
                    data-msg-required="Veuillez Entrez votre nom et prenoms svp."
                    className="form-control text-3 h-auto py-3-5"
                    aria-disabled={requestInProgress}
                    name="name"
                    placeholder="Nom et Prenoms"
                    required />
            </div>

            <div className="form-group col mb-3-5">
                <input type="number"
                    onChange={handleContactChange}
                    value={state.contact}
                    data-msg-required="Veuillez Entrez votre contact svp."
                    className="form-control text-3 h-auto py-3-5"
                    aria-disabled={requestInProgress}
                    maxLength={15}
                    name="contact"
                    placeholder="Contact"
                    required />
            </div>
        </div>
        <div className="row">
            <div className="form-group col mb-3-5">
                <input type="email"
                    onChange={handleEmailChange}
                    value={state.mail}
                    data-msg-required="Veuillez Entrez votre addresse mail."
                    data-msg-email="Please enter a valid email address."
                    className="form-control text-3 h-auto py-3-5"
                    aria-disabled={requestInProgress}
                    name="email"
                    placeholder="Adresse mail" required />
            </div>
        </div>
        <div className="row">
            <div className="form-group col mb-3-5">
                <input type="text"
                    onChange={handleSubjectChange}
                    value={state.subject}
                    data-msg-required="Veuillez Entrez votre sujet."
                    className="form-control text-3 h-auto py-3-5"
                    aria-disabled={requestInProgress}
                    name="subject"
                    placeholder="Votre sujet"
                    required />
            </div>
        </div>
        <div className="row">
            <div className="form-group col mb-3-5">
                <textarea
                    onChange={handleMessageChange}
                    value={state.message}
                    data-msg-required="Veuillez entrer votre message."
                    aria-disabled={requestInProgress}
                    className="form-control text-3 h-auto py-3-5"
                    name="message"
                    placeholder="Votre message" required></textarea>
            </div>
        </div>
        <div className="row">
            <div className="form-group col">
                <button type="submit"
                    className="custom-view-more d-inline-flex align-items-center btn btn-primary font-weight-semibold rounded-0 text-3-5 btn-px-4 btn-py-2"
                    data-loading-text="Loading..." aria-disabled={requestInProgress}>
                    Soumettre
                    <img width="27" height="27" src="/img/demos/construction/icons/arrow-right.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light ms-2'}" />
                </button>
            </div>
        </div>
    </form>


}