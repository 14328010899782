import React from "react";

export const NotFound = () => {

    return <>
    <section className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7">
            <div className="container">
                <div className="row mt-5">
                   
                    <div className="col-md-12 align-self-center order-1">
                        <ul className="breadcrumb breadcrumb-light d-block text-center">
                            <li><a href="/">Accueil</a></li>
                            <li><a>Page introuvable</a></li>
                           
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className="http-error mb-4">
            <div className="row justify-content-center py-3">
                <div className="col-lg-12 text-center">
                    <div className="http-error-main">
                        <h2>{"404!"}</h2>
                        <p>{"La ressource que vous essayer de consulter est introuvable"}</p>
                        <p>{"Si il s'agit d'une annonce, elle a été surement supprimée."}</p>
                    </div>
                </div>

            </div>
        </section>
    </>;
}