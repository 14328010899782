import { apiRoutes } from "../../constants/api-routes";
import { APIClient } from "../../helpers/api_helper";
import { SimpleAnnonce } from "../../types/annonce";
import { GenericNetworkResponse } from "../../types/generic-network-response";

export const getRelatedAnnonceRequest = async (id: string): Promise<GenericNetworkResponse<Array<SimpleAnnonce> | null>> => {
    const api = new APIClient();
    let caughtError = null;
    const url = apiRoutes.admin.annonce.getRelatedAnnonces.replace(':annonceId', id);

    try {
        const response = await api.get(url, null);
        return { data: response.data, error: null };
    }
    catch (error) {
        caughtError = error;
    }

    return { data: null, error: caughtError }
}