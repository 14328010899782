

import React, { ReactNode } from "react";
import styles from "./form-control-wrapper.module.scss";

type FormControlWrapperProps = {
    label: string;
    tooltipText: string;
    children: ReactNode;

}

export const FormControlWrapper = ({ children, label, tooltipText }: FormControlWrapperProps) => {

    return <div className={`mb-3 ${styles.formControlWrapper}`}>
        <h4 className={`font-weight-bold m-0 ${styles.labelContainer}`}>{label}
            <span  data-bs-toggle="tooltip" data-bs-animation="false" data-bs-placement="right" title={tooltipText}>
                <a className={`btn btn-default btn-circle`}>
                    <i className="bi bi-exclamation-circle float-right"></i></a>
            </span>
        </h4>
        <hr className={"mt-2 mb-2"} />
        {children}
    </div>
}